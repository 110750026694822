import { Component } from 'react';
import { LayoutDaPaginaDeEntrada } from '../../../componentes';
import { BotaoComTexto, LinkComIcone } from '@digix-ui/componentes';
import { apiDaInscricao } from '../../../servicos/axios';
import { manterSomenteNumeros } from '../../../helpers/mascaras';
import { endAjaxCall } from '../../../actions/requisicoesAjax.actions';
import { connect } from 'react-redux';
import { consultarExistenciaDeInscricao } from '../../../servicos/inscricao';
import CampoDeTextoComMascara from '../../../componentes/CampoDeTextoComMascara';

class RedefinirSenha extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cpf: ''
    };
  }

  componentDidMount() {
    document.querySelector('#foco-inicial-do-teclado').focus();
  }

  atualizarCpf = (event) => {
    const { value } = event.target;
    this.setState({
      cpf: value
    });
  };

  validarSeCpfPossuiOnzeDigitos = (cpf) => {
    if (cpf.length != 11) {
      this.setState({
        mensagemDeErroNoCampoCpf: 'CPF inválido'
      });
      return false;
    }
    return true;
  };

  obterTelefoneCelularDoTitular = async (event) => {
    event.preventDefault();
    let cpfApenasComNumeros = manterSomenteNumeros(this.state.cpf);

    var cpfPossuiOnzeDigitos = this.validarSeCpfPossuiOnzeDigitos(cpfApenasComNumeros);
    if (!cpfPossuiOnzeDigitos)
      return;

    try {
      await consultarExistenciaDeInscricao(cpfApenasComNumeros, this.props.dispatch);

      var respostaDaRequisicao = await apiDaInscricao().get(`/titulares/${cpfApenasComNumeros}/contatos`);
      const telefoneCelularComDigitosOcultos = respostaDaRequisicao.data['telefoneCelular'];
      const emailComCaracteresOcultos = respostaDaRequisicao.data['email'];
      const tipoDeContato = respostaDaRequisicao.data['tipoDeContato'];
      this.props.endAjaxCall();
      this.setState({
        telefoneCelularComDigitosOcultos: telefoneCelularComDigitosOcultos,
        emailComCaracteresOcultos: emailComCaracteresOcultos,
        tipoDeContato: tipoDeContato
      });
      this.props.history.push('/redefinir-senha/envio-de-codigo',
        { cpf: this.state.cpf, telefoneCelularComDigitosOcultos, emailComCaracteresOcultos, tipoDeContato });
    } catch (e) {
      if (!e.response || !e.response.data) {
        this.setState({
          mensagemErroDoContato: `Não foi possível obter o contato do titular no momento.
         Caso o problema persista entre em contato com o suporte.`});
        return;
      }
    }
  };

  limparMensagemDeErroDeTamanhoDoCpf = () => {
    this.setState({
      mensagemDeErroNoCampoCpf: ''
    });
  };

  limparMensagemDeErro = () => {
    this.setState({
      mensagemErroDoContato: false
    });
  };

  render() {
    const { location, history } = this.props;
    const esqueceuASenha = location && location.state && location.state.esqueceuASenha;
    const inputEvents = {
      onChange: this.atualizarCpf,
      onFocus: this.limparMensagemDeErroDeTamanhoDoCpf
    };
    const mensagemErroDoContato = this.state.mensagemErroDoContato;
    const mensagemDeErroNoCampoCpf = this.state.mensagemDeErroNoCampoCpf;

    return (
      <LayoutDaPaginaDeEntrada
        mensagemDeErro={mensagemErroDoContato}
        limparMensagemDeErro={this.limparMensagemDeErro}>
        <div className="grade">
          <div className="grade__coluna">
            <LinkComIcone
              href="/login"
              onClick={() => history.push('/login')}
              cor="info"
              icone="far fa-long-arrow-left"
              posicaoDoIcone="esquerda"
              className="u-texto-negrito"> Voltar</LinkComIcone>
            <h2 id="foco-inicial-do-teclado" tabIndex="-1" className="titulo titulo_grande texto_cor-terciaria u-margem-superior-media u-margem-inferior-pequena">
              {esqueceuASenha ? 'Redefinir senha' : 'Criar uma senha'}
            </h2>
            <p className="texto">
              Vamos localizar um número de contato na sua inscrição para redefinir sua senha.
            </p>
          </div>

          <form onSubmit={this.obterTelefoneCelularDoTitular}
            className="formulario formulario_medio u-margem-inferior-media">
            <CampoDeTextoComMascara
              label="Digite seu CPF"
              mascara="999.999.999-99"
              name="cpf"
              tipo="text"
              erro={mensagemDeErroNoCampoCpf}
              {...inputEvents} />
            <div className="grade__linha">
              <div className="grade__coluna">
                <BotaoComTexto
                  cor="terciaria"
                  classesAuxiliares="botao_largura-total">
                  Avançar
                </BotaoComTexto>
              </div>
            </div>
          </form>
        </div>
      </LayoutDaPaginaDeEntrada>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    endAjaxCall: () => dispatch(endAjaxCall())
  };
};

export default connect(null, mapDispatchToProps)(RedefinirSenha);