import { Component } from 'react';
import { CampoDeTexto, LayoutDaPaginaDeEntrada } from '../../../componentes';
import { BotaoComTexto, LinkComIcone, Mensagem, GerenciadorDeMensagem } from '@digix-ui/componentes';
import { apiDaInscricao, apiDaInscricaoSemLoading } from '../../../servicos/axios';
import { manterSomenteNumeros } from '../../../helpers/mascaras';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { endAjaxCall } from '../../../actions/requisicoesAjax.actions';

class ConfirmacaoDeCodigo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      podeReenviarCodigo: false,
      tempoParaReenviarOCodigo: 60000
    };
  }

  componentDidMount() {
    document.querySelector('#foco-inicial-do-teclado').focus();

    const location = this.props.location;
    if (location && location.state && location.state.deveMostrarMensagemFlutuante) {
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'sucesso',
        icone: 'far fa-check-circle',
        titulo: 'Tudo certo!',
        texto: 'Enviamos o código de confirmação para o contato informado'
      });
    }
    setTimeout(() => this.setState({ podeReenviarCodigo: true }), this.state.tempoParaReenviarOCodigo);
  }

  reenviarCodigoEAlterarVisibilidadeDoReenvio = async (cpf, tipoDeContatoParaConfirmacao) => {
    let cpfComApenasNumeros = manterSomenteNumeros(cpf);
    this.alterarVisibilidadeDoReenvioDoCodigo();
    await this.reenviarCodigo(cpfComApenasNumeros, tipoDeContatoParaConfirmacao);
  };

  alterarVisibilidadeDoReenvioDoCodigo = () => {
    this.setState({
      podeReenviarCodigo: !this.state.podeReenviarCodigo
    });

    setTimeout(() => this.setState({ podeReenviarCodigo: !this.state.podeReenviarCodigo }), this.state.tempoParaReenviarOCodigo);
  };

  reenviarCodigo = async (cpfComApenasNumeros, tipoDeContatoParaConfirmacao) => {
    let mensagemDeReenvioDeCodigo = {};
    try {
      await apiDaInscricaoSemLoading().post(`/titulares/${cpfComApenasNumeros}/solicitacaoDeCodigo/${tipoDeContatoParaConfirmacao}`);
      mensagemDeReenvioDeCodigo = {
        tipo: 'sucesso',
        icone: 'far fa-check-circle',
        texto: 'Enviamos o código de confirmação para o contato informado',
        titulo: 'Tudo certo!'
      };
    } catch (e) {
      let mensagemDeErroDaResposta;
      if (!e.response || !e.response.data) {
        mensagemDeReenvioDeCodigo.texto = `Não foi possível enviar o código de confirmação no momento.
        Caso o problema persista entre em contato com o suporte.`;
        GerenciadorDeMensagem.criarMensagem({
          tipo: 'erro',
          icone: 'far fa-times-circle',
          titulo: 'Identificamos um erro:',
          texto: mensagemDeErroDaResposta
        });
        return;
      }
      mensagemDeErroDaResposta = typeof (e.response.data) === 'string'
        ? e.response.data
        : e.response.data.mensagens[0];
      mensagemDeReenvioDeCodigo = {
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: mensagemDeErroDaResposta
      };
    }

    GerenciadorDeMensagem.criarMensagem(mensagemDeReenvioDeCodigo);
  };

  mensagemDeReenviarOCodigo = (cpf, tipoDeContatoParaConfirmacao) => (
    <p className="texto">
      Não recebeu?
      <LinkComIcone
        href="#"
        cor="info"
        icone="far fa-redo"
        posicaoDoIcone="direita"
        onClick={() => this.reenviarCodigoEAlterarVisibilidadeDoReenvio(cpf, tipoDeContatoParaConfirmacao)}
        className="u-texto-negrito u-margem-esquerda-pequena">
        Enviar outro código </LinkComIcone>
    </p>
  );

  mensagemDeAguardarOReenvioDoCodigo = () => (
    <p className="texto">
      Aguarde, isto pode demorar até 1 minuto.
    </p>
  );

  confirmarCodigo = async (cpf, event) => {
    event.preventDefault();
    const { codigo } = this.state;
    if (!codigo) {
      this.setState({
        erroNoCampoDoCodigo: 'Insira o código recebido para confirmar'
      });
      return;
    }

    const cpfComApenasNumeros = manterSomenteNumeros(cpf);
    const codigoComLetrasMaiusculas = codigo.toUpperCase();
    try {
      await apiDaInscricao().post(`/titulares/${cpfComApenasNumeros}/confirmacaoDeCodigo`, { codigoDeConfirmacao: codigoComLetrasMaiusculas });
      this.props.endAjaxCall();
      this.props.history.push('/definir-senha', { cpf: cpfComApenasNumeros, codigo: codigoComLetrasMaiusculas });
    } catch (e) {
      if (!e.response || !e.response.data) {
        GerenciadorDeMensagem.criarMensagem({
          tipo: 'erro',
          icone: 'far fa-times-circle',
          titulo: 'Identificamos um erro:',
          texto: 'Não foi possível confirmar o código. Tente novamente ou entre em contato com um posto de atendimento da AGEHAB'
        });
        return;
      }
      const mensagemDeErroDaResposta = typeof (e.response.data) === 'string'
        ? e.response.data
        : e.response.data.mensagens[0];
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: mensagemDeErroDaResposta
      });
    }
  };

  onFocus = () => this.removerErro();

  removerErro = () => {
    this.setState({
      erroNoCampoDoCodigo: ''
    });
  };

  onChange = event => {
    const { value } = event.target;
    this.setState({
      codigo: value
    });
    this.removerErro();
  };

  render() {
    const inputEvents = {
      onChange: this.onChange,
      onFocus: this.onFocus
    };
    const podeReenviarCodigo = this.state.podeReenviarCodigo;
    const propsRecebidos = this.props.location.state || {};
    const cpf = propsRecebidos.cpf;
    const telefoneCelularComDigitosOcultos = propsRecebidos.telefoneCelularComDigitosOcultos || '';
    const emailComCaracteresOcultos = propsRecebidos.emailComCaracteresOcultos || '';
    const tipoDeContatoParaConfirmacao = propsRecebidos.tipoDeContatoParaConfirmacao || '';
    return (
      <LayoutDaPaginaDeEntrada>
        {!cpf && !telefoneCelularComDigitosOcultos &&
          <Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />
        }
        <div className="grade">
          <div className="grade__coluna">
            <h2 className="titulo titulo_grande texto_cor-terciaria u-margem-inferior-pequena">Confirmação</h2>

            {tipoDeContatoParaConfirmacao === 'sms' &&
              <p className="texto">Acabamos de enviar uma mensagem SMS com o código de confirmação para o <strong>número {telefoneCelularComDigitosOcultos}</strong>.</p>
            }

            {tipoDeContatoParaConfirmacao === 'email' &&
              <>
                <p className="texto">Acabamos de enviar um email com o código de confirmação para o <strong>{emailComCaracteresOcultos}</strong>.</p>
                <Mensagem
                  tipo="atencao"
                  icone="fal fa-envelope">
                  Confira a pasta de <strong>spam</strong> e <strong>lixeira</strong> do seu email caso o código ainda não tenha chegado na sua caixa de entrada
                </Mensagem>
              </>
            }

          </div>

          <div id="foco-inicial-do-teclado" tabIndex="-1" className="grade__coluna">
            {podeReenviarCodigo ? this.mensagemDeReenviarOCodigo(cpf, tipoDeContatoParaConfirmacao) : this.mensagemDeAguardarOReenvioDoCodigo()}
          </div>

          <form onSubmit={(event) => this.confirmarCodigo(cpf, event)}
            className="formulario formulario_medio u-margem-inferior-media">
            <CampoDeTexto
              label="Digite o código de confirmação"
              name="codigo"
              tipo="text"
              erro={this.state.erroNoCampoDoCodigo}
              {...inputEvents} />
            <div className="grade__linha">
              <div className="grade__coluna">
                <BotaoComTexto
                  cor="terciaria"
                  name="senha"
                  classesAuxiliares="botao_largura-total">
                  Confirmar
                </BotaoComTexto>
              </div>
            </div>
          </form>
        </div>
      </LayoutDaPaginaDeEntrada>
    );
  }
}

export default connect(null, { endAjaxCall })(ConfirmacaoDeCodigo);