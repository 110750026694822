import React, { useState } from 'react';
//@ts-ignore
import Select from 'react-select';
import { CampoCheckbox, CampoDeValorEmReais } from '../';
import MensagemDeErro from '../MensagemDeErro';
import CampoRadioButton from '../CampoRadioButton';
import Section from '../Section';
import { Mensagem, Dialogo } from '@digix-ui/componentes';
import IMoradia from '../../interfaces/IMoradia';
import opcoesDaInfraestrutura from './opcoesDaInfraestrutura';
import situacoesDeMoradia from './situacoesDeMoradia';
import materiaisDeConstrucao from './materiaisDeConstrucao';
import AreasDeRisco from './areasDeRisco';
import { IPerguntaDeMoradiaValidacaoErroCampos } from '../../interfaces/IPerguntaDeMoradiaValidacao';

interface IEventOnChange {
  target: { name: string; value: string };
}

interface IPerguntasDeMoradiaProps {
  dadosMoradia: IMoradia,
  moraEmAreaDeRisco: boolean,
  erros?: IPerguntaDeMoradiaValidacaoErroCampos,
  atualizarInfraestrutura: (infraestruturas: string[]) => void,
  atualizarAreasDeRisco: (areasDeRisco: string[]) => void,
  atualizarMoraEmAreaDeRisco: (moraAreaDeRisco: boolean) => void,
  atualizarMaterialDaConstrucao: (materialDaConstrucao: string) => void,
  atualizarValorDoAluguel: (valorDoAluguel: number) => void,
  onBlur: (evento: React.ChangeEvent<HTMLInputElement>) => void,
  onFocus: (evento: React.ChangeEvent<HTMLInputElement>) => void,
  atualizarSituacaoMoradia: (situacaoMoradia: string) => void,
  limparAreasDeRisco: () => void,
  onClick: (evento: React.MouseEvent<HTMLButtonElement>) => void,
  atualizarErros: (erros: IPerguntaDeMoradiaValidacaoErroCampos) => void,
  disabled: boolean,
  atualizarOnusExcessivo: (onusExcessivo: boolean) => void,
  atualizarAdensamentoExcessivo: (adensamentoExcessivo: boolean) => void,
  atualizarHabitacaoPrecaria: (possuiHabitacaoPrecaria: boolean) => void,
}

const PerguntasDeMoradia = ({ dadosMoradia, moraEmAreaDeRisco, erros,
  atualizarValorDoAluguel, onBlur, onFocus, atualizarSituacaoMoradia,
  onClick, limparAreasDeRisco, atualizarInfraestrutura, atualizarMoraEmAreaDeRisco,
  atualizarMaterialDaConstrucao, atualizarAreasDeRisco, atualizarErros, disabled, atualizarOnusExcessivo, atualizarAdensamentoExcessivo, atualizarHabitacaoPrecaria }: IPerguntasDeMoradiaProps) => {

  const [exibirDialogo, alterarExibicaoDoDialogo] = useState(false);

  const abrirModal = (evento: React.ChangeEvent<HTMLInputElement>) => {
    alterarExibicaoDoDialogo(true);
    atualizarMoraEmAreaDeRisco(evento.target.value === 'true');
  };

  const onChangeCheckboxOpcaoDaInfraestrutura = (evento: IEventOnChange) => {
    const { name, value } = evento.target;
    const estaSelecionado = value === 'true';
    let infraestruturas: string[];

    if (estaSelecionado)
      infraestruturas = dadosMoradia.infraestruturas.concat(name);
    else
      infraestruturas = dadosMoradia.infraestruturas.filter((programa) => programa != name);

    atualizarInfraestrutura(infraestruturas);
  };

  const handleOnChangeSituacaoMoradia = (selected: { label: string, value: string }) => {
    if (selected) {
      atualizarSituacaoMoradia(selected.value);
      removerMensagemDeValidacao('situacao');
    }
  };

  const handleOnChangeMaterialDaConstrucao = (selected: { label: string, value: string }) => {
    if (selected) {
      atualizarMaterialDaConstrucao(selected.value);
      removerMensagemDeValidacao('materialDaConstrucao');
    }
  };

  const handleOnChangeValorDoAluguel = (evento: React.ChangeEvent<HTMLInputElement>) => {
    atualizarValorDoAluguel(Number.parseFloat(evento.target.value));
  };

  const removerMensagemDeValidacao = (campo: string) => {
    atualizarErros({ ...erros, [campo]: false });
  };

  const handleOnChangeAdensamentoExcessivo = (evento: React.ChangeEvent<HTMLInputElement>) => {
    atualizarAdensamentoExcessivo(evento.target.value == 'true');
  };

  const handleOnChangeHabitacaoPrecaria = (evento: React.ChangeEvent<HTMLInputElement>) => {
    atualizarHabitacaoPrecaria(evento.target.value == 'true');
  };
 
  const handleOnChangeOnusExcessivo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    atualizarOnusExcessivo(value == 'true');
  };

  return (
    <div className="formulario formulario_medio">
      <Section>
        <div className="grade__coluna">
          <h2 className="titulo titulo_medio texto_cor-secundaria"><strong>Moradia atual</strong></h2>
        </div>
        <div className="grade__coluna">
          <div className={`formulario__input-react-select ${erros?.situacao && 'formulario__input-react-select_erro'}`}>
            <label className="formulario__label">
              Situação da moradia
            </label>
            {erros?.situacao && <MensagemDeErro campo='situacao' />}
            <Select
              name='situacaoMoradia'
              id='situacaoMoradia'
              placeholder="Selecione uma opção"
              value={dadosMoradia?.situacao}
              options={situacoesDeMoradia}
              clearable={false}
              onChange={handleOnChangeSituacaoMoradia}
              disabled={disabled}
            />
          </div>
        </div>
        {dadosMoradia?.situacao === 'Alugada' &&
          <div>
            <CampoDeValorEmReais
              label="Valor do aluguel da moradia"
              name="valorDoAluguel"
              value={dadosMoradia?.valorDoAluguel || 0}
              erro={erros?.valorDoAluguel ? 'Campo obrigatório' : ''}
              onChange={handleOnChangeValorDoAluguel}
              onBlur={onBlur}
              onFocus={onFocus}
              disabled={false || disabled} />
            <div className="grade__coluna">
              <fieldset className="formulario__grupo-de-inputs">
                <legend className="formulario__label">
                  Encontra-se em Ônus Excessivo?
                  <p className="formulario__descricao">
                    Famílias que despendem mais de 30% de sua renda com aluguel, comprovado pela razão do valor expresso em contrato ou recibo de aluguel pela renda familiar mensal
                  </p>
                </legend>
                {erros?.onusExcessivo && <MensagemDeErro />}
                <CampoRadioButton
                  mesmaLinha
                  name="onusExcessivo"
                  value={'true'}
                  label="Sim"
                  checked={dadosMoradia.possuiOnusExcessivo?.toString() || ''}
                  onChange={handleOnChangeOnusExcessivo}
                  disabled={disabled} />
                <CampoRadioButton
                  mesmaLinha
                  name="onusExcessivo"
                  value={'false'}
                  label="Não"
                  checked={dadosMoradia.possuiOnusExcessivo?.toString() || ''}
                  onChange={handleOnChangeOnusExcessivo}
                  disabled={disabled} />
              </fieldset>
            </div>
          </div>
        }
        {dadosMoradia?.situacao != 'EmSituacaoDeRua' &&
        <>
          <div className="grade__coluna">
            <legend className="formulario__label">Encontra-se em adensamento excessivo?
              <p className="formulario__descricao">
                Famílias em que o número médio de moradores é superior ao três pessoas por dormitório.
              </p>            
            </legend>
            {erros?.adensamentoExcessivo && <MensagemDeErro campo='adensamentoExcessivo' />}
            <CampoRadioButton
              mesmaLinha
              name="adensamentoExcessivo"
              value={'true'}
              label="Sim"
              checked={dadosMoradia?.possuiAdensamentoExcessivo?.toString() || ''}
              onChange={handleOnChangeAdensamentoExcessivo}
              disabled={disabled} />
            <CampoRadioButton
              mesmaLinha
              name="adensamentoExcessivo"
              value={'false'}
              label="Não"
              checked={dadosMoradia?.possuiAdensamentoExcessivo?.toString() || ''}
              onChange={handleOnChangeAdensamentoExcessivo}
              disabled={disabled} />
          </div>

          <div className="grade__coluna">
            <div className={`formulario__input-react-select ${erros?.materialDaConstrucao && 'formulario__input-react-select_erro'}`}>
              <label
                className="formulario__label">
                Qual é o principal material usado na construção da moradia atual?
              </label>
              {erros?.materialDaConstrucao && <MensagemDeErro campo='materiaisDeConstrucao' />}
              <Select
                name='materialDaConstrucao'
                id='materialDaConstrucao'
                placeholder="Selecione uma opção"
                value={dadosMoradia?.materialDaConstrucao}
                options={materiaisDeConstrucao}
                onChange={handleOnChangeMaterialDaConstrucao}
                disabled={disabled}
              />
            </div>
          </div>
        </>
        }

        <div className="grade__coluna">
          <legend className="formulario__label">Vive em Habitação Precária?
            <p className="formulario__descricao">
              Morar em imóvel construído com restos de materiais ou estar morando em um local não residencial (prédio abandonado, vagão de trem, tenda, etc).
            </p>            
          </legend>
          {erros?.habitacaoPrecaria && <MensagemDeErro campo='habitacaoPrecaria' />}
          <CampoRadioButton
            mesmaLinha
            name="habitacaoPrecaria"
            value={'true'}
            label="Sim"
            checked={dadosMoradia?.possuiHabitacaoPrecaria?.toString() || ''}
            onChange={handleOnChangeHabitacaoPrecaria}
            disabled={disabled} />
          <CampoRadioButton
            mesmaLinha
            name="habitacaoPrecaria"
            value={'false'}
            label="Não"
            checked={dadosMoradia?.possuiHabitacaoPrecaria?.toString() || ''}
            onChange={handleOnChangeHabitacaoPrecaria}
            disabled={disabled} />
        </div>

        <div>
          <div className="grade__coluna">
            <label className='formulario__label'>
              Assinale as opções sobre o endereço que se aplicam à moradia:
            </label>
            <p className="formulario__descricao">
              Caso a moradia não contemple nenhuma das opções a seguir, pule esta pergunta.
            </p>
          </div>
          {opcoesDaInfraestrutura.map((opcao) =>
            (<div className="grade__coluna" key={opcao.name}>
              <CampoCheckbox
                name={opcao.name}
                label={opcao.label}
                checked={dadosMoradia.infraestruturas.includes(opcao.name)}
                onChange={onChangeCheckboxOpcaoDaInfraestrutura}
                disabled={disabled} />
            </div>
            ))
          }
        </div>
      </Section>
      <Section>
        <div className="grade__coluna">
          <h2 className="titulo titulo_medio texto_cor-secundaria"><strong>Áreas de risco</strong></h2>
          <p className="formulario__descricao">
            Áreas de risco são áreas consideradas impróprias para o assentamento humano por estarem sujeitas a riscos naturais ou decorrentes de intervenção humana.
          </p>
          <legend className="formulario__label">Mora em área de risco?</legend>
          {erros?.moraEmAreaDeRisco && <MensagemDeErro campo='moraEmAreaDeRisco' />}
          <CampoRadioButton
            mesmaLinha
            name="moraEmAreaDeRisco"
            value={'true'}
            label="Sim"
            checked={moraEmAreaDeRisco.toString()}
            onChange={abrirModal}
            disabled={disabled} />
          <CampoRadioButton
            mesmaLinha
            name="moraEmAreaDeRisco"
            value={'false'}
            label="Não"
            checked={moraEmAreaDeRisco.toString()}
            onChange={limparAreasDeRisco}
            disabled={disabled} />
          {erros?.areasDeRisco && <MensagemDeErro campo="areasDeRisco" mensagem={erros?.areasDeRisco ? 'É necessário selecionar pelo menos uma opção' : ''} />}
          {moraEmAreaDeRisco && (
            <>
              <AreasDeRisco
                areasDeRisco={dadosMoradia?.areasDeRisco}
                atualizarAreasDeRisco={atualizarAreasDeRisco}
                disabled={disabled} />
              <Mensagem
                tipo='atencao'
                titulo="Atenção!"
                icone='far fa-exclamation-triangle'>
                Caso sua moradia esteja em área de risco, <strong>você precisará comprovar posteriormente esta informação por meio de laudo emitido pela Defesa Civil.</strong>
              </Mensagem>
            </>)}
        </div>
        <hr />
        <div className="grade__coluna">
          <button
            disabled={disabled}
            className="botao botao_medio botao_cor-secundaria botao_largura-total"
            onClick={onClick}>
            Salvar
          </button>
        </div>
      </Section>

      <Dialogo
        data-test-id="modalAreaDeRisco"
        tamanho='medio'
        deveSerExibido={exibirDialogo}
        fecharDialogo={() => alterarExibicaoDoDialogo(false)}
        titulo='Atenção'
        corpo={() => {
          return (<p>Caso sua moradia esteja em área de risco, <strong>você precisará comprovar posteriormente esta informação por meio de laudo emitido pela Defesa Civil.</strong></p>
          );
        }}
        rodape={() => {
          return (<button className="botao botao_cor-sucesso" type="button" onClick={() => alterarExibicaoDoDialogo(false)}>Ok</button>);
        }} />
    </div>
  );
};

export default PerguntasDeMoradia;