import React from 'react';
//@ts-ignore
import Select from 'react-select';
import { Mensagem } from '@digix-ui/componentes';
import CampoRadioButton from '../../../CampoRadioButton';
import MensagemDeErro from '../../../MensagemDeErro';
import CampoDeTextoComMascara from '../../../CampoDeTextoComMascara';
import IRegimeDeCasamento from '../../../../interfaces/IRegimeDeCasamento';
import { mapearRegimeDeCasamentoParaSelect } from '../../../../selectors/RegimesDeCasamento';
import estadoCivis from './estadocivis';

interface ICampoEstadoCivilProps {
  estadoCivil: string,
  uniaoEstavel: boolean,
  dataDoCasamento?: Date,
  regimeDeCasamento?: IRegimeDeCasamento,
  erroEstadoCivil?: boolean,
  erroUniaoEstavel?: boolean,
  erroDataDoCasamento?: string,
  erroRegimeDeCasamento?: string,
  ehEstrangeiro: boolean,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onFocus: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onChangeRegimeDeCasamento: (selected?: IRegimeDeCasamento) => void,
  onChangeEstadoCivil: (event: React.ChangeEvent<HTMLInputElement>) => void,
  disabled: boolean,
  regimesDeCasamento: IRegimeDeCasamento[]
}

const estadosCivisQuePermitemExibicaoDeUniaoEstavel = ['solteiro', 'divorciado', 'viuvo'];

interface IEventOnChange {
  target: { name: string, value: string }
}

const permiteExibirCampoDeUniaoEstavel = (estadoCivil: string) => {
  return estadosCivisQuePermitemExibicaoDeUniaoEstavel.includes(estadoCivil);
};

const CampoEstadoCivil = ({ estadoCivil, uniaoEstavel, dataDoCasamento, regimeDeCasamento,
  erroEstadoCivil, erroUniaoEstavel, erroDataDoCasamento, erroRegimeDeCasamento, ehEstrangeiro, onChange, onBlur, onFocus, onChangeRegimeDeCasamento, onChangeEstadoCivil,
  disabled, regimesDeCasamento }: ICampoEstadoCivilProps) => {

  const ehCasado = estadoCivil == 'casado';
  const mostrarUniaoEstavel = permiteExibirCampoDeUniaoEstavel(estadoCivil);
  const mostrarAvisoCertidaoCasamentoTraduzida = ehEstrangeiro && ehCasado;

  const handleOnChangeEstadoCivil = (selected: { label: string, value: string }) => {
    const target: IEventOnChange = {
      target: {
        name: 'estadoCivil',
        value: !!selected ? selected.value : ''
      }
    };
    //@ts-ignore
    onChangeEstadoCivil(target);
  };

  const handleOnChangeRegimeDeCasamento = (selected: { label: string, value: string }) => {
    if (selected) {
      const regimeDeCasamento: IRegimeDeCasamento = { id: parseInt(selected.value), nome: selected.label };
      onChangeRegimeDeCasamento(regimeDeCasamento);
    } else
      onChangeRegimeDeCasamento(selected);
  };
  return (
    <div className="grade__linha grade__linha_alinhar-vertical-a-base">
      <div className="grade__coluna grade__coluna_6 u-alinhamento-vertical-superior">
        <div className={`formulario__input-react-select ${erroEstadoCivil && 'formulario__input-react-select_erro'}`}>
          <label className="formulario__label">
            Estado civil
          </label>
          {erroEstadoCivil && <MensagemDeErro campo='estadoCivil' />}
          <Select
            name="estadoCivil"
            id="estadoCivil"
            placeholder="Selecione uma opção"
            value={estadoCivil}
            options={estadoCivis}
            onChange={handleOnChangeEstadoCivil}
            disabled={disabled} />
        </div>
      </div>
      <div className="grade__coluna grade__coluna_6">
        {mostrarUniaoEstavel &&
          <fieldset className="formulario__grupo-de-inpus">
            <legend className="formulario__label">
              Está em regime de união estável?
              <p className="formulario__descricao">
                Também chamados &quot;amasiados&quot;, &quot;conviventes&quot; e &quot;juntados&quot;, a união estável é a relação afetiva entre duas pessoas, de caráter duradouro, público e com o objetivo de constituir família.
              </p>
            </legend>
            {erroUniaoEstavel && <MensagemDeErro campo='uniaoEstavel' />}
            <CampoRadioButton
              mesmaLinha
              name="uniaoEstavel"
              value="true"
              label="Sim"
              checked={uniaoEstavel.toString()}
              onChange={onChange}
              disabled={disabled} />
            <CampoRadioButton
              mesmaLinha
              name="uniaoEstavel"
              value="false"
              label="Não"
              checked={uniaoEstavel.toString()}
              onChange={onChange}
              disabled={disabled} />
          </fieldset>
        }
        {mostrarAvisoCertidaoCasamentoTraduzida &&
          <Mensagem
            tipo='atencao'
            titulo="Atenção!"
            icone='far fa-exclamation-circle'>
            Se a certidão de casamento não estiver traduzida, procure orientação de como traduzir para o português.
          </Mensagem>
        }
      </div>
      {ehCasado && <>
        <CampoDeTextoComMascara
          colunas='6'
          label="Data do casamento"
          mascara="99/99/9999"
          name="dataDoCasamento"
          pattern="\d*"
          value={dataDoCasamento?.toString() || ''}
          erro={erroDataDoCasamento}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled} />
        <div className="grade__coluna grade__coluna_6">
          <div className={`formulario__input-react-select ${erroRegimeDeCasamento && 'formulario__input-react-select_erro'}`}>
            <label className="formulario__label">
              Regime de casamento
            </label>
            {erroRegimeDeCasamento && <MensagemDeErro campo='regimeDeCasamento' />}
            <Select
              name="regimeDeCasamento"
              id="regimeDeCasamento"
              placeholder="Selecione uma opção"
              value={regimeDeCasamento?.id}
              options={mapearRegimeDeCasamentoParaSelect(regimesDeCasamento)}
              onChange={handleOnChangeRegimeDeCasamento}
              disabled={disabled} />
          </div>
        </div>
      </>}
    </div>
  );
};

export default CampoEstadoCivil;