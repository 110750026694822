import { AxiosError } from 'axios';
import { GerenciadorDeMensagem } from '@digix-ui/componentes';

interface IDadosDeErro {
  ehExcecaoDeDominio?: boolean,
  ehExcecaoDeAplicacao?: boolean,
  mensagens: string[]
}

export const tratarErro = (error: AxiosError) => {
  let mensagem = '';

  if (error.response) {
    const dadosDeErro = error.response?.data as IDadosDeErro;

    const ehExcecaoTratada = dadosDeErro?.ehExcecaoDeDominio || dadosDeErro?.ehExcecaoDeAplicacao;
    const mensagens = dadosDeErro?.mensagens;
    mensagem = ehExcecaoTratada ? mensagens?.join('\n') : '';

    exibirMensagem(mensagem);
  }
};

export const tratarMensagem = (mensagem: string) => {
  exibirMensagem(mensagem);
};

const exibirMensagem = (mensagem: string) => {
  const mensagemPadrao = 'Ocorreu um erro. Tente novamente, caso o problema persista, entre em contato com o suporte.';

  GerenciadorDeMensagem.criarMensagem({
    tipo: 'erro',
    icone: 'far fa-times-circle',
    titulo: 'Atenção!',
    texto: mensagem || mensagemPadrao,
  });
};