import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
//@ts-ignore
import { Link, useHistory  } from 'react-router-dom';
import { efetuarLogOut } from '../../actions/login.actions';
import '../../estilos/estilos.scss';
//@ts-ignore
import logoAgehab from '../Logos/logo-agehab.svg';
import initialState from '../../reducers/initialState.reducer';
import IPretendente from '../../interfaces/IPretendente';

interface HeaderProps {
  subTitulo: string,
  comBotaoVoltar?: boolean,
  voltarPara?: string,
  urlBotaoVoltar?: string
}

const identificadorDoProduto = process.env.REACT_APP_IDENTIFICADOR_DO_PRODUTO;

const Header = ({ subTitulo, comBotaoVoltar, voltarPara, urlBotaoVoltar = '/menu-inscricao' }: HeaderProps) => {

  const history = useHistory();
  const ehNovaInscricao: boolean = useSelector((state: typeof initialState) => state.ehNovaInscricao as boolean);
  const inscricao = useSelector((state: typeof initialState) => state.inscricao);
  const login = useSelector((state: typeof initialState) => state.login as any);
  const dadosTitular = useSelector((state: typeof initialState) => state.dadosTitular as IPretendente);
  const dadosConjuge = useSelector((state: typeof initialState) => state.dadosConjuge as IPretendente);
  const urlBaseDoTermoDeUso: string = 'https://termos.digix.com.br/termos/visualizacao?produto=' + identificadorDoProduto;
  const urlBaseDaPoliticaDePrivacidade: string = 'https://termos.digix.com.br/politicasDePrivacidade?produto=' + identificadorDoProduto;
  const dispatch = useDispatch();

  const deslogar = () => dispatch(efetuarLogOut());

  const voltarPagina = () => {
    voltarPara && !ehNovaInscricao
      ? history.push(voltarPara)
      : history.goBack();
  };

  const obterNome = () => {
    if (!inscricao.id)
      return '';

    const nome = login.cpf === dadosTitular.cpf ? dadosTitular.nome : dadosConjuge.nome;
    return nome ? nome.split(' ')[0] : '';
  };

  return (
    <div>
      <header className="cabecalho-sistema cabecalho-sistema_fixo-ao-topo">
        <div className="cabecalho-sistema__container-primario">
          {comBotaoVoltar ?
            <Link to={urlBotaoVoltar}>              
              <img src={logoAgehab} className='cabecalho-sistema__logo' alt="logo agehab" />              
            </Link>
            :
            <img src={logoAgehab} className='cabecalho-sistema__logo' alt="logo agehab" />
          }
        </div>

        <div className="cabecalho-sistema__container-secundario">
          <nav className="cabecalho-sistema__menu" aria-label="Menu secundário">
            <ul className="cabecalho-sistema__lista-de-itens">
              <li className="dropdown dropdown_direita">
                <button
                  id="botao-abrir-menu-administrativo"
                  className="cabecalho-sistema__item-de-menu"
                  aria-label={`Opções para o usuário ${obterNome()}`}
                  aria-haspopup="true">
                  <span className="cabecalho-sistema__icone-do-usuario">
                    <i className="fal fa-user"></i>
                  </span>
                  <span className="cabecalho-sistema__nome-do-usuario">{obterNome()}</span>
                  <i className="fal fa-angle-down u-margem-esquerda-pequena"></i>
                </button>
                <ul className="dropdown__lista" aria-label="Submenu">
                  <li className="dropdown__item">
                    <a href={urlBaseDoTermoDeUso} target="_blank" rel="noopener noreferrer" className="dropdown__link">
                      <span className="dropdown__icone">
                        <i className="fal fa-file-check" aria-hidden="true"></i>
                      </span>
                      Termos de uso
                    </a>
                  </li>
                  <li className="dropdown__item">
                    <a href={urlBaseDaPoliticaDePrivacidade} target="_blank" rel="noopener noreferrer" className="dropdown__link">
                      <span className="dropdown__icone">
                        <i className="fal fa-lock" aria-hidden="true"></i>
                      </span>
                      Políticas de privacidade
                    </a>
                  </li>
                  <li className="dropdown__item">
                    <a href="#" className="dropdown__link" id="linkLogOff" onClick={deslogar}>
                      <span className="dropdown__icone">
                        <i className="fal fa-sign-out" aria-hidden="true"></i>
                      </span>
                      Sair
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <div className="barra-navegacao barra-navegacao_fixa">
        <div className="barra-navegacao__container">
          <div className="barra-navegacao__conteudo-primario">
            {comBotaoVoltar &&
              <a
                href="#"
                data-test-id="botao-voltar"
                className="botao botao_icone botao_sem-preenchimento botao_medio botao_cor-terciaria"
                onClick={voltarPagina}
                aria-label="Voltar para página anterior">
                <i className="fal fa-arrow-left" aria-hidden="true"></i>
              </a>
            }
            <div className="barra-navegacao__conteudo-principal">
              <div className="barra-navegacao__conteudo-primario">
                <div className="barra-navegacao__container-titulos">
                  <h1 className="barra-navegacao__titulo">{subTitulo}</h1>
                </div>
              </div>

              {comBotaoVoltar &&
                <div className="barra-navegacao__conteudo-secundario">
                  <Link to={urlBotaoVoltar}
                    data-test-id="botao-barra-de-navegacao-a-direita"
                    className="botao botao_medio botao_cor-secundaria botao_texto-branco">
                    Menu de inscrição
                  </Link>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Header;

