import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import initialState from '../../reducers/initialState.reducer';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import Container from '../../componentes/Container';
import ContainerCabecalho from '../../componentes/ContainerCabecalho';
import ContainerConteudo from '../../componentes/ContainerConteudo';
import ContainerRodape from '../../componentes/ContainerRodape';
import { Dialogo, GerenciadorDeMensagem } from '@digix-ui/componentes';
import { Header, IndicadorPasso } from '../../componentes';
import ItemImovel from './componentes/ItemImovel';
import '../../estilos/estilos.scss';
import { excluirImovel } from '../../actions/imoveis.actions';
import IImovelProprio from '../../interfaces/IImovelProprio';
import BarraDeNavegacao from '../../componentes/BarraDeNavegacao';
import { excluir } from '../../servicos/imovelProprio';

const Imoveis = () => {

  const inscricao = useSelector((state: typeof initialState) => state.inscricao);
  const idDaInscricao = useSelector((state: typeof initialState) => state.inscricao.id) as number;
  const imoveis = useSelector((state: typeof initialState) => state.imoveis) as IImovelProprio[];
  const ehAcessoAdministrativo: boolean = useSelector((state: typeof initialState) => state.ehAcessoAdministrativo);
  const [idImovelSelecionadoParaExclusao, setIdImovelSelecionadoParaExclusao] = useState<number | undefined>();
  const [deveExibirDialogoDeExclusao, setDeveExibirDialogoDeExclusao] = useState<boolean>();
  const ehNovaInscricao = useSelector((state: typeof initialState) => state.ehNovaInscricao) as boolean;
  const dispatch = useDispatch();
  const ehFormularioSomenteLeitura = useSelector((state: typeof initialState) => state.ehFormularioSomenteLeitura) as boolean;

  const inscricaoEstaVazioNaMemoria = Object.keys(inscricao).length == 1;
  useEffect(() => {
    if (inscricaoEstaVazioNaMemoria && !ehNovaInscricao)
      dispatch(push('/menu-inscricao'));

  }, [dispatch, ehFormularioSomenteLeitura, ehNovaInscricao, inscricaoEstaVazioNaMemoria]);

  const exibirDialogoDeExclusao = (idDoImovel: number) => {
    setIdImovelSelecionadoParaExclusao(idDoImovel);
    setDeveExibirDialogoDeExclusao(true);
  };

  const fecharDialogoDeExclusao = () => {
    setIdImovelSelecionadoParaExclusao(undefined);
    setDeveExibirDialogoDeExclusao(false);
  };

  const excluirImovelSelecionado = async () => {
    if (idImovelSelecionadoParaExclusao) {
      await excluir(idDaInscricao, idImovelSelecionadoParaExclusao);
      dispatch(excluirImovel(idImovelSelecionadoParaExclusao));

      GerenciadorDeMensagem.criarMensagem({
        tipo: 'sucesso',
        icone: 'far fa-check-circle',
        titulo: 'Tudo certo!',
        texto: 'Imóvel ou terreno excluído com sucesso.'
      });
      fecharDialogoDeExclusao();
    }
  };

  const avancar = () => {
    dispatch(push('/programas-sociais'));
  };

  return (
    <>
      {(!inscricaoEstaVazioNaMemoria || ehNovaInscricao) &&
        <div className="pagina-fundo">
          {!ehAcessoAdministrativo && (
            //@ts-ignore
            <Header subTitulo={'Cadastro - Imóveis'} comBotaoVoltar={!ehNovaInscricao} urlBotaoVoltar={'/menu-inscricao'} />
          )}
          {ehAcessoAdministrativo && (
            <BarraDeNavegacao
              titulo='Cadastro - Imóveis'
              comBotaoVoltar={true}
              acaoBotaoVoltar={() => dispatch(push('/menu-inscricao'))}
              comBotaoADireita={true}
              textoBotaoADireita="Menu de inscrição"
              iconeBotaoADireita={false}
              acaoBotaoADireita={() => dispatch(push('/menu-inscricao'))}
            />
          )}
          <div className="pagina-container">
            <Container tamanhoDoContainer="pequeno">
              <ContainerCabecalho>
                <IndicadorPasso
                  iconeDoPasso='fa-home'
                  titulo='Imóveis próprios'
                  descricao='Informe apenas imóveis próprios ou terrenos que estejam registrados em nome do(a) titular ou cônjuge.' />
              </ContainerCabecalho>
              <ContainerConteudo>
                <h2 data-test-id="quantidadeDeImoveis" className="titulo titulo_medio u-margem-superior-media"><strong>Quantidade de imóveis: {imoveis.length}</strong></h2>
                {imoveis.length ?
                  imoveis.map((imovel: IImovelProprio) =>
                    <ItemImovel
                      key={imovel.id}
                      imovel={imovel}
                      removerImovelDaListagem={exibirDialogoDeExclusao}
                      disabled={ehFormularioSomenteLeitura} />
                  ) :
                  <p data-test-id="informacaoDeNaoPossuirImovel" className="texto_medio u-margem-inferior-media">Não há nenhum imóvel cadastrado no momento.</p>
                }
                {!ehFormularioSomenteLeitura &&
                  <Link data-test-id="botaoAdicionarImovel" to='/novo-imovel' className="botao botao_contorno botao_cor-secundaria botao_largura-total lista-de-cadastro__botao">
                    <i className="fal fa-plus-circle" aria-hidden="true" />Adicionar imóvel
                  </Link>
                }
              </ContainerConteudo>
              <ContainerRodape>
                <button
                  disabled={ehFormularioSomenteLeitura}
                  data-test-id="botaoAvancarImoveis"
                  className="botao botao_medio botao_cor-secundaria botao_largura-total"
                  onClick={avancar}>
                  Avançar
                </button>
              </ContainerRodape>
            </Container>
          </div>
          <Dialogo
            data-test-id="modalExcluirImovel"
            tamanho='mini'
            deveSerExibido={deveExibirDialogoDeExclusao}
            fecharDialogo={fecharDialogoDeExclusao}
            acaoPrincipal={() => excluirImovel}
            titulo='Atenção'
            corpo={() => {
              return (<p>Deseja excluir o imóvel cadastrado?</p>);
            }}
            rodape={() => {
              return (
                <>
                  <button data-test-id="modalBotaoCancelar" className="botao botao_cor-secundaria" type="button" onClick={fecharDialogoDeExclusao}>Cancelar</button>
                  <button data-test-id="modalBotaoExcluir" className="botao botao_cor-secundaria botao_contorno" type="button" onClick={excluirImovelSelecionado}>Excluir</button>
                </>
              );
            }} />
        </div>}
    </>
  );
};
export default Imoveis;