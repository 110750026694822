import {
  CampoCheckbox,
  CampoRadioButton,
  Header,
  IndicadorPasso,
  MensagemDeErro,
} from '../../componentes';
import BarraDeNavegacao from '../../componentes/BarraDeNavegacao';
import Container from '../../componentes/Container';
import ContainerCabecalho from '../../componentes/ContainerCabecalho';
import ContainerConteudo from '../../componentes/ContainerConteudo';
import ContainerRodape from '../../componentes/ContainerRodape';
import IProgramasSociais from '../../interfaces/IProgramasSociais';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import initialState from '../../reducers/initialState.reducer';
import { push } from 'connected-react-router';
import React from 'react';
import { GerenciadorDeMensagem } from '@digix-ui/componentes';
import { salvarProgramasSociais } from '../../servicos/programasSociais';

interface IEventOnChange {
  target: { name: string; value: string };
}

interface IOpcaoDeProgramaSocial {
  name: string;
  label: string;
}

const opcoesDeProgramasSociais: IOpcaoDeProgramaSocial[] = [
  { name: 'BolsaFamilia', label: 'Bolsa Família' },
  { name: 'BPC', label: 'Benefício de Prestação Continuada' },
  { name: 'Outros', label: 'Outros' },
];

const ProgramasSociais = () => {
  const iniciarProgramaSociais = () => {
    if (ehNovaInscricao)
      dadosProgramasSociais.programasSelecionados = [];

    return dadosProgramasSociais;
  };
  const inscricao = useSelector((state: typeof initialState) => state.inscricao);
  const ehNovaInscricao = useSelector((state: typeof initialState) => state.ehNovaInscricao) as boolean;
  const dadosProgramasSociais = useSelector((state: typeof initialState) => state.programasSociais as IProgramasSociais);
  const [programasSociais, setProgramasSociais] = useState<IProgramasSociais>(iniciarProgramaSociais);
  const [clicouEmSalvar, setClicouEmSalvar] = useState<boolean>(false);
  const idDaInscricao = useSelector((state: typeof initialState) => state.inscricao.id) as Number;
  const ehAcessoAdministrativo = useSelector((state: typeof initialState) => state.ehAcessoAdministrativo) as boolean;
  const respondeuSePossuiBeneficio: boolean = programasSociais.possuiBeneficioDeProgramaSocial != null && programasSociais.possuiBeneficioDeProgramaSocial != undefined;
  const informouAlgumBeneficio: boolean = programasSociais.programasSelecionados?.length > 0;
  const deveInformarAlgumBeneficio = programasSociais.possuiBeneficioDeProgramaSocial && !informouAlgumBeneficio;
  const dispatch = useDispatch();
  const ehFormularioSomenteLeitura = useSelector((state: typeof initialState) => state.ehFormularioSomenteLeitura) as boolean;

  const inscricaoEstaVazioNaMemoria = Object.keys(inscricao).length == 1;
  useEffect(()  =>  {
    if (inscricaoEstaVazioNaMemoria && !ehNovaInscricao)
      dispatch(push('/menu-inscricao'));

  }, []);

  const onChangeCheckbox = (event: IEventOnChange) => {
    const { name } = event.target;
    const estaSelecionado = event.target.value === 'true';
    let programasSelecionados: string[];

    if (estaSelecionado)
      programasSelecionados = programasSociais.programasSelecionados.concat(name);
    else
      programasSelecionados = programasSociais.programasSelecionados.filter((programa) => programa != name);

    setProgramasSociais({
      ...programasSociais,
      programasSelecionados: programasSelecionados,
    });
  };

  const onChangeRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    const informouPossuirBeneficio = event.target.value == 'true';

    if (informouPossuirBeneficio) {
      setProgramasSociais({
        ...programasSociais,
        possuiBeneficioDeProgramaSocial: true,
      });
      return;
    }

    setProgramasSociais({
      possuiBeneficioDeProgramaSocial: false,
      programasSelecionados: [],
    });
  };

  const salvar = async () => {
    setClicouEmSalvar(true);

    const formularioEstaValido = validarFormulario();
    if (!formularioEstaValido) return;

    await salvarProgramasSociais(programasSociais, idDaInscricao, dispatch);

    GerenciadorDeMensagem.criarMensagem({
      tipo: 'sucesso',
      icone: 'far fa-check-circle',
      titulo: 'Tudo certo!',
      texto: 'Informações dos programas sociais cadastradas com sucesso.',
    });

    if (ehNovaInscricao) dispatch(push('/programas-habitacionais'));
    else dispatch(push('/menu-inscricao'));
  };

  const validarFormulario = () => {
    if (!respondeuSePossuiBeneficio || deveInformarAlgumBeneficio) {
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: 'Formulário inválido. Por favor informe os campos obrigatórios.',
      });
      return false;
    }
    return true;
  };

  return (
    <>
      {(!inscricaoEstaVazioNaMemoria || ehNovaInscricao) && 
      <div className="pagina-informacoes-da-familia">
        {!ehAcessoAdministrativo && (
        //@ts-ignore
          <Header
            subTitulo="Cadastro - Programas sociais"
            comBotaoVoltar={true}
            urlBotaoVoltar={'/menu-inscricao'}
          />
        )}
        {ehAcessoAdministrativo && (
          <BarraDeNavegacao
            titulo="Cadastro - Programas sociais"
            comBotaoVoltar={true}
            acaoBotaoVoltar={() => dispatch(push('/menu-inscricao'))}
            comBotaoADireita={true}
            textoBotaoADireita="Menu de inscrição"
            iconeBotaoADireita={false}
            acaoBotaoADireita={() => dispatch(push('/menu-inscricao'))}
          />
        )}

        <div className="pagina-informacoes-da-familia__container">
          <Container tamanhoDoContainer={''}>
            <ContainerCabecalho className="">
              <IndicadorPasso
                iconeDoPasso="fa-credit-card"
                titulo="Programas sociais"
                descricao="Preencha todos os campos abaixo. Todos os dados deverão ser comprovados posteriormente."
              />
            </ContainerCabecalho>
            <ContainerConteudo className="">
              <fieldset className="formulario__grupo-de-inputs">
                <legend className="formulario__label">
                  Recebe benefício de algum programa social do governo?
                </legend>
                {clicouEmSalvar && !respondeuSePossuiBeneficio && <MensagemDeErro />}
                <CampoRadioButton
                  mesmaLinha
                  name="possuiBeneficioDeProgramaSocial"
                  value="true"
                  label="Sim"
                  checked={
                    programasSociais.possuiBeneficioDeProgramaSocial != null
                      ? programasSociais.possuiBeneficioDeProgramaSocial.toString()
                      : ''
                  }
                  onChange={onChangeRadioButton}
                  disabled={ehFormularioSomenteLeitura}
                />
                <CampoRadioButton
                  mesmaLinha
                  name="possuiBeneficioDeProgramaSocial"
                  value="false"
                  label="Não"
                  checked={
                    programasSociais.possuiBeneficioDeProgramaSocial != null
                      ? programasSociais.possuiBeneficioDeProgramaSocial.toString()
                      : ''
                  }
                  onChange={onChangeRadioButton}
                  disabled={ehFormularioSomenteLeitura}
                />
              </fieldset>
              {programasSociais.possuiBeneficioDeProgramaSocial && (
                <>
                  <hr />
                  <fieldset className="formulario__grupo-de-inputs" data-test-id="programas-sociais">
                    <legend className="formulario__label">
                      Assinale os programas sociais que recebe do governo:
                    </legend>
                    {clicouEmSalvar && deveInformarAlgumBeneficio && <MensagemDeErro />}
                    {opcoesDeProgramasSociais.map((opcao) => (
                      <CampoCheckbox
                        key={opcao.name}
                        name={opcao.name}
                        label={opcao.label}
                        checked={programasSociais.programasSelecionados.includes(opcao.name)}
                        onChange={onChangeCheckbox}
                        disabled={ehFormularioSomenteLeitura}
                      />
                    ))}
                  </fieldset>
                </>
              )}
            </ContainerConteudo>
            <ContainerRodape>
              <div className="grade__coluna">
                <button
                  disabled={ehFormularioSomenteLeitura}
                  className="botao botao_medio botao_cor-secundaria botao_largura-total"
                  onClick={salvar}
                >
                  Avançar
                </button>
              </div>
            </ContainerRodape>
          </Container>
        </div>
      </div>}
    </>
  );
};

export default ProgramasSociais;