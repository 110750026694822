import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Layout from './telas/Layout';
import { createServer, Response } from 'miragejs';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { verificarSeEhAmbienteDeHomologacao, verificarSeEhAmbienteDeProducao } from './helpers/verificadorDeAmbientes';
import { validarAcessoAdministrativo, validarAcessoPublico } from './servicos/autenticacao';
import { inicializarClarity } from './servicos/clarity';
import { ACESSO_ADMINISTRATIVO_CLARITY, ACESSO_PUBLICO_CLARITY } from './helpers/identificadorDoProjetoNoClarity';

const ehAmbienteDeHomologacao = verificarSeEhAmbienteDeHomologacao();
const ehAmbienteDeProducao = verificarSeEhAmbienteDeProducao();
const ehAcessoAdministrativo = validarAcessoAdministrativo();
const ehAcessoPublico = validarAcessoPublico();
const deveHabilitarScriptDoClarity = ehAmbienteDeProducao && (ehAcessoAdministrativo || ehAcessoPublico);

if(deveHabilitarScriptDoClarity){
  const identificadorDoProjetoNoClarity = ehAcessoAdministrativo ? ACESSO_ADMINISTRATIVO_CLARITY : ACESSO_PUBLICO_CLARITY;
  inicializarClarity(identificadorDoProjetoNoClarity);
}

if (ehAmbienteDeHomologacao || ehAmbienteDeProducao){
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    environment: ehAmbienteDeProducao ? 'producao' : 'homologacao',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    tracePropagationTargets: ['**'],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(  
  <BrowserRouter>
    <Layout />
  </BrowserRouter>  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (window.Cypress) {
  
  let otherDomains = ['http://localhost/', 'http://localhost:5000/'];
  let methods = ['get', 'put', 'patch', 'post', 'delete'];

  createServer({
    environment: 'test',
    routes() {
      for (const domain of ['/', ...otherDomains]) {
        for (const method of methods) {
          this[method](`${domain}*`, async (schema, request) => {

            let [status, headers, body] = await window.handleFromCypress(
              request
            );
            return new Response(status, headers, body);
          });
        }
      }
    },
  });
}