import React, { useState } from 'react';
import IPassosDaSeparacaoDeInscricao from '../../interfaces/IPassosDaSeparacaoDaInscricao';
import IPretendente from '../../interfaces/IPretendente';
import IDependente from '../../interfaces/IDependente';
import IImovelProprio from '../../interfaces/IImovelProprio';
import './styles.scss';
import CampoRadioButton from '../CampoRadioButton';
import CampoDeTexto from '../CampoDeTexto';
import setaParaDireita from '../../assets/seta-para-direita.svg';
import { finalizarSeparacaoDeInscricao } from '../../servicos/separacaoDeInscricao';
import { GerenciadorDeMensagem } from '@digix-ui/componentes';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { 
  ETAPA_DEPENDENTES, ETAPA_QUEM_PERMANECE_NA_INSCRICAO, ETAPA_ESTADO_CIVIL,
  ETAPA_IMOVEIS, ETAPA_MOTIVO_DA_SEPARACAO, ETAPA_PROGRAMAS_SOCIAIS,
  ETAPA_CONFIRMACAO, 
} from '../../helpers/etapasDaSeparacaoDeInscricao';
import { STATUS_ATUAL, STATUS_COMPLETO } from '../../helpers/statusDaEtapaDeSeparacaoDeInscricao';
import ISeparacaoDeInscricao from '../../interfaces/ISeparacaoDeInscricao';

interface EtapasDaSeparacaoDeInscricaoProps {
  numeroDaInscricao: string,
  idDaInscricao: number,
  titular: IPretendente,
  conjuge: IPretendente,
  dependentes: IDependente[],
  imoveis: IImovelProprio[],
  programasSociais: string[],
  ehSeparacaoComplexa: boolean,
  etapas: IPassosDaSeparacaoDeInscricao[],
  setEtapas: (etapas: IPassosDaSeparacaoDeInscricao[]) => void,
  reiniciarEtapas : () => void
}

const EtapasDaSeparacaoDeInscricao = ({numeroDaInscricao, idDaInscricao, titular, conjuge, dependentes, imoveis, programasSociais, etapas, ehSeparacaoComplexa, setEtapas, reiniciarEtapas}: EtapasDaSeparacaoDeInscricaoProps) => {
  
  const dispatch = useDispatch();
  const etapaAtual = etapas.filter(x => x.status == STATUS_ATUAL)[0];
  const [numeroEtapaAtual, setNumeroEtapaAtual] = useState<number>(etapaAtual?.numeroEtapa || 1);

  const onChangeMotivoSeparacao = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEtapas(etapas.map(etapa => etapa.status == STATUS_ATUAL ? {...etapa, motivoSeparacao: {...etapa.motivoSeparacao, motivoDaSeparacao: value }}: etapa));
  };

  const onChangeJustificativaSeparacao = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEtapas(etapas.map(etapa => etapa.status == STATUS_ATUAL ? {...etapa, motivoSeparacao: {...etapa.motivoSeparacao, justificativa: value }}: etapa));
  };

  const proximaEtapa = (numeroEtapa: number, sentido: string = 'avancar') => {

    if (sentido == 'voltar' && etapaAtual.nomeDoPasso == ETAPA_MOTIVO_DA_SEPARACAO)
      dispatch(push('listagem-de-inscricoes'));

    if (sentido == 'voltar' && etapaAtual.nomeDoPasso == ETAPA_CONFIRMACAO){
      setNumeroEtapaAtual(1);
      reiniciarEtapas();
    }else{
      const removeEtapaAtual = etapas.map(etapa => etapa.status == STATUS_ATUAL ? {...etapa, status: STATUS_COMPLETO}: etapa);
      const novaEtapaAtual = removeEtapaAtual.map(etapa => etapa.numeroEtapa == numeroEtapa ? {...etapa, status: STATUS_ATUAL} :  etapa);
      setEtapas(novaEtapaAtual);
      setNumeroEtapaAtual(numeroEtapa);
    }
  };

  const checarPreenchimento = () => {
    if (etapaAtual.nomeDoPasso == ETAPA_MOTIVO_DA_SEPARACAO)
      return !!etapaAtual.motivoSeparacao?.motivoDaSeparacao ? ehSeparacaoComplexa ? !!etapaAtual.motivoSeparacao?.justificativa ? false : true : false : true;
    if (etapaAtual.nomeDoPasso == ETAPA_QUEM_PERMANECE_NA_INSCRICAO)
      return !(!!etapaAtual.quemFicaraNaInscricao?.idPretendente);
    if (etapaAtual.nomeDoPasso == ETAPA_ESTADO_CIVIL)
      return !(!!etapaAtual.estadoCivil?.estadoCivil);
    if (etapaAtual.nomeDoPasso == ETAPA_DEPENDENTES)
      return !(!!etapaAtual.dependentes && etapaAtual.dependentes?.length == dependentes.length);
    if (etapaAtual.nomeDoPasso == ETAPA_IMOVEIS)
      return !(!!etapaAtual.imoveis && etapaAtual.imoveis?.length == imoveis.length);
    if (etapaAtual.nomeDoPasso == ETAPA_PROGRAMAS_SOCIAIS)
      return !(!!etapaAtual.programasSociais && etapaAtual.programasSociais?.length == programasSociais.length);
  };

  const onChangeQuemFicaraNaInscricao = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEtapas(etapas.map(etapa => etapa.status == STATUS_ATUAL ? {...etapa, quemFicaraNaInscricao: { idPretendente: parseInt(value) }}: etapa));
  };

  const obterNomeDeQuemFicaNaInscricao = () =>{
    const etapaDonoDaInscricao = etapas.find(x => x.nomeDoPasso == ETAPA_QUEM_PERMANECE_NA_INSCRICAO);
    if (etapaDonoDaInscricao)
      return titular.id == etapaDonoDaInscricao.quemFicaraNaInscricao?.idPretendente ? titular.nome: conjuge.nome;

    return '';
  };

  const onChangeEstadoCivilDonoDaInscricao = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEtapas(etapas.map(etapa => etapa.status == STATUS_ATUAL ? {...etapa, estadoCivil: { estadoCivil: value }}: etapa));
  };

  const onChangeDependentesDaInscricao = (idPretendente: Number, idDependente: Number, nome: string) => {

    let dependentes = etapaAtual.dependentes;
    dependentes = dependentes?.filter(dependente =>  dependente.idDependente != idDependente) || [];
    dependentes.push({ idDependente: idDependente, idPretendente: idPretendente, nome: nome});
    
    setEtapas(etapas.map(etapa => etapa.status == STATUS_ATUAL ? {...etapa, dependentes: dependentes  }: etapa));
  };

  const onChangeImovelDaInscricao = (idPretendente: Number, idImovel: Number, nome: string) => {

    let imoveis = etapaAtual.imoveis;
    imoveis = imoveis?.filter(imovel =>  imovel.idImovel != idImovel) || [];
    imoveis.push({ idImovel: idImovel, idPretendente: idPretendente, nome: nome});
    
    setEtapas(etapas.map(etapa => etapa.status == STATUS_ATUAL ? {...etapa, imoveis: imoveis  }: etapa));
  };

  const onChangeProgramaSociaisDaInscricao = (idPretendente: Number, programaSocial: string) => {

    let programaSociais = etapaAtual.programasSociais;
    programaSociais = programaSociais?.filter(programa =>  programa.programaSocial != programaSocial) || [];
    programaSociais.push({ programaSocial: programaSocial, idPretendente: idPretendente});
    
    setEtapas(etapas.map(etapa => etapa.status == STATUS_ATUAL ? {...etapa, programasSociais: programaSociais  }: etapa));
  };

  const motivoDaSeparacao = () => {
    const etapaMotivoSeparacao = etapas.find(etapa => etapa.nomeDoPasso == ETAPA_MOTIVO_DA_SEPARACAO);
    if (etapaMotivoSeparacao){
      const motivo = etapaMotivoSeparacao.motivoSeparacao?.motivoDaSeparacao;
      return motivo == 'Obito' ? 'Óbito' : motivo == 'SeparacaoJudicial' ? 'Separação judicial' : 'Solicitação do pretendente';
    }
    return '';
  };

  const nomePretendente = (inscricao: string) =>{
    const idPretendente =  etapas.find(etapa => etapa.nomeDoPasso == ETAPA_QUEM_PERMANECE_NA_INSCRICAO)?.quemFicaraNaInscricao?.idPretendente;
    if (inscricao == STATUS_ATUAL) 
      return titular.id == idPretendente ? titular.nome : conjuge.nome;
    else 
      return titular.id == idPretendente ? conjuge.nome : titular.nome;
  };

  const listaDeDependentes = (inscricao: string) => {
    const dependentes = etapas.find(etapa => etapa.nomeDoPasso == ETAPA_DEPENDENTES)?.dependentes;
    const idPretendente =  etapas.find(etapa => etapa.nomeDoPasso == ETAPA_QUEM_PERMANECE_NA_INSCRICAO)?.quemFicaraNaInscricao?.idPretendente;

    if (inscricao == STATUS_ATUAL)
      return dependentes?.filter(pessoa => pessoa.idPretendente == idPretendente).map(dependente => dependente.nome) || []; 
    else
      return dependentes?.filter(pessoa => pessoa.idPretendente != idPretendente).map(dependente => dependente.nome) || []; 
  };

  const listaDeImoveis = (inscricao: string) => {
    const imoveis = etapas.find(etapa => etapa.nomeDoPasso == ETAPA_IMOVEIS)?.imoveis;
    const idPretendente =  etapas.find(etapa => etapa.nomeDoPasso == ETAPA_QUEM_PERMANECE_NA_INSCRICAO)?.quemFicaraNaInscricao?.idPretendente;

    if (inscricao == STATUS_ATUAL)
      return imoveis?.filter(imovel => imovel.idPretendente == idPretendente).map(imovel => imovel.nome)  || []; 
    else
      return imoveis?.filter(imovel => imovel.idPretendente != idPretendente).map(imovel => imovel.nome)  || []; 
  };

  const listaDeProgramaSociais = (inscricao: string) => {
    const programasSociais = etapas.find(etapa => etapa.nomeDoPasso== ETAPA_PROGRAMAS_SOCIAIS)?.programasSociais;
    const idPretendente =  etapas.find(etapa => etapa.nomeDoPasso == ETAPA_QUEM_PERMANECE_NA_INSCRICAO)?.quemFicaraNaInscricao?.idPretendente;

    if (inscricao == STATUS_ATUAL)
      return programasSociais?.filter(programa => programa.idPretendente == idPretendente)
        .map(programa => programa.programaSocial == 'BolsaFamilia' ? 'Bolsa Família' : programa.programaSocial == 'BPC' ? 'Benefício de Prestação Continuada' : 'Outros') || []; 
    else
      return programasSociais?.filter(programa => programa.idPretendente != idPretendente)
        .map(programa => programa.programaSocial == 'BolsaFamilia' ? 'Bolsa Família' : programa.programaSocial == 'BPC' ? 'Benefício de Prestação Continuada' : 'Outros') || []; 
  };

  const finalizarSeparacao = async () => {
    
    const pretendenteIdQueFicaraNaInscricao = etapas.find(etapa => etapa.nomeDoPasso == ETAPA_QUEM_PERMANECE_NA_INSCRICAO)?.quemFicaraNaInscricao?.idPretendente;
    
    const idPretendenteParaRemover = titular.id == pretendenteIdQueFicaraNaInscricao ? conjuge.id : titular.id;
    
    const dependentes = etapas.find(etapa => etapa.nomeDoPasso == ETAPA_DEPENDENTES)?.dependentes;
    const depentesQueNaoFicaraNaInscricao = dependentes?.filter(dependente => dependente.idPretendente == idPretendenteParaRemover).map(dep => dep.idDependente);

    const programas = etapas.find(etapa => etapa.nomeDoPasso == ETAPA_PROGRAMAS_SOCIAIS)?.programasSociais;
    const programasSociaisParaRemover = programas?.filter(programaSocial => programaSocial.idPretendente == idPretendenteParaRemover).map(programa => programa.programaSocial);

    const imoveisProprios =  etapas.find(etapa => etapa.nomeDoPasso == ETAPA_IMOVEIS)?.imoveis;
    const imoveisParaRemover = imoveisProprios?.filter(imovel => imovel.idPretendente == idPretendenteParaRemover).map(imvProprio => imvProprio.idImovel);

    const idLocalDeAtendimento = JSON.parse(localStorage.getItem('localDeAtendimento') || '')?.id;

    const payload: ISeparacaoDeInscricao = {
      idInscricaoOrigem : idDaInscricao,
      idPretendenteParaRemover: idPretendenteParaRemover,
      motivoDaSeparacao: etapas.find(etapa => etapa.nomeDoPasso == ETAPA_MOTIVO_DA_SEPARACAO)?.motivoSeparacao?.motivoDaSeparacao || '',
      novoEstadoCivil:etapas.find(etapa => etapa.nomeDoPasso == ETAPA_ESTADO_CIVIL)?.estadoCivil?.estadoCivil || '',
      idDosDependentesParaRemocao: depentesQueNaoFicaraNaInscricao || [],
      programasSociaisParaRemocao: programasSociaisParaRemover || [],
      idDosImoveisParaRemocao: imoveisParaRemover || [],
      justificativa: etapas.find(etapa => etapa.nomeDoPasso == ETAPA_MOTIVO_DA_SEPARACAO)?.motivoSeparacao?.justificativa,
      idLocalDeAtendimento: idLocalDeAtendimento
    };

    await finalizarSeparacaoDeInscricao(payload);

    GerenciadorDeMensagem.criarMensagem({
      tipo: 'sucesso',
      icone: 'far fa-check-circle',
      titulo: 'Concluído!',
      texto: 'Separação de inscrição concluída com sucesso!'
    });
    dispatch(push('/listagem-de-inscricoes'));
  };

  return (
    <>
      {etapaAtual && <div>
        {etapaAtual.nomeDoPasso == ETAPA_MOTIVO_DA_SEPARACAO &&
        <div>
          <p className='titulo-pergunta'>Qual é o motivo da separação?</p>
          <CampoRadioButton
            mesmaLinha={false}
            name={'motivo'}
            label={'Óbito'}
            value={'Obito'}
            checked={etapaAtual.motivoSeparacao?.motivoDaSeparacao || ''}
            onChange={onChangeMotivoSeparacao} />
          <CampoRadioButton
            mesmaLinha={false}
            name={'motivo'}
            label={'Separação judicial'}
            value={'SeparacaoJudicial'}
            checked={etapaAtual.motivoSeparacao?.motivoDaSeparacao || ''}
            onChange={onChangeMotivoSeparacao} />
          {titular.estadoCivil.toLowerCase() != 'casado' && 
          <CampoRadioButton
            mesmaLinha={false}
            name={'motivo'}
            label={'Solicitação do pretendente'}
            value={'SolicitacaoDoPretendente'}
            checked={etapaAtual.motivoSeparacao?.motivoDaSeparacao || ''}
            onChange={onChangeMotivoSeparacao} />
          }

          {ehSeparacaoComplexa && 
          <div className='justificativa-separacao'>
            <CampoDeTexto
              label={'O que justifica a separação?'}
              name={'justificativa'}
              value={etapaAtual.motivoSeparacao?.justificativa || ''}
              maxLength={450}
              erro={''}
              placeholder={'Exemplo: Foi apresentada averbação de separação, processo 9999999-99.9999.9.99.9999'}
              onChange={onChangeJustificativaSeparacao}
            />
          </div>}
        </div>
        }

        {etapaAtual.nomeDoPasso == ETAPA_QUEM_PERMANECE_NA_INSCRICAO &&
        <div>
          <p className='titulo-pergunta'>Quem vai permanecer na inscrição?</p>
          <CampoRadioButton
            mesmaLinha={false}
            name={'permaneceNaInscricao'}
            label={titular.nome}
            value={titular.id.toString()}
            checked={etapaAtual.quemFicaraNaInscricao?.idPretendente?.toString() || ''}
            onChange={onChangeQuemFicaraNaInscricao} />
          <CampoRadioButton
            mesmaLinha={false}
            name={'permaneceNaInscricao'}
            label={conjuge.nome}
            value={conjuge.id.toString()}
            checked={etapaAtual.quemFicaraNaInscricao?.idPretendente?.toString() || ''}
            onChange={onChangeQuemFicaraNaInscricao} />
        </div>
        }

        {etapaAtual.nomeDoPasso == ETAPA_ESTADO_CIVIL &&
        <div>
          <p className='titulo-pergunta'>Qual será o novo estado civil do(a) {obterNomeDeQuemFicaNaInscricao()}?</p>
          <CampoRadioButton
            mesmaLinha={false}
            name={'estadoCivil'}
            label={'Viúvo'}
            value={'viuvo'}
            checked={etapaAtual.estadoCivil?.estadoCivil?.toString() || ''}
            onChange={onChangeEstadoCivilDonoDaInscricao} />
          <CampoRadioButton
            mesmaLinha={false}
            name={'estadoCivil'}
            label={'Casado(a)'}
            value={'casado'}
            checked={etapaAtual.estadoCivil?.estadoCivil?.toString() || ''}
            onChange={onChangeEstadoCivilDonoDaInscricao} />
          <CampoRadioButton
            mesmaLinha={false}
            name={'estadoCivil'}
            label={'Solteiro(a)'}
            value={'solteiro'}
            checked={etapaAtual.estadoCivil?.estadoCivil?.toString() || ''}
            onChange={onChangeEstadoCivilDonoDaInscricao} />
          <CampoRadioButton
            mesmaLinha={false}
            name={'estadoCivil'}
            label={'Divorciado(a)'}
            value={'divorciado'}
            checked={etapaAtual.estadoCivil?.estadoCivil?.toString() || ''}
            onChange={onChangeEstadoCivilDonoDaInscricao} />
          <CampoRadioButton
            mesmaLinha={false}
            name={'estadoCivil'}
            label={'Separado(a) juridicamente'}
            value={'SeparadoJudicialmente'}
            checked={etapaAtual.estadoCivil?.estadoCivil?.toString() || ''}
            onChange={onChangeEstadoCivilDonoDaInscricao} />          
        </div>
        }

        {(etapaAtual.nomeDoPasso == ETAPA_DEPENDENTES && dependentes.length > 0) &&
        <div>
          {dependentes.map((dependente, index) => (
            <div key={index}>
              <p className='titulo-pergunta'>Quem será o responsável por {dependente.nome}?</p>
              <CampoRadioButton
                mesmaLinha={false}
                name={dependente.id.toString()}
                label={titular.nome}
                value={dependente.id.toString()}
                checked={etapaAtual.dependentes?.find(x=> x.idPretendente == titular.id && x.idDependente == dependente.id)?.idDependente.toString() || ''}
                onChange={() => onChangeDependentesDaInscricao(titular.id, dependente.id, dependente.nome)} />
              <CampoRadioButton
                mesmaLinha={false}
                name={dependente.id.toString()}
                label={conjuge.nome}
                value={dependente.id.toString()}
                checked={etapaAtual.dependentes?.find(x=> x.idPretendente == conjuge.id && x.idDependente == dependente.id)?.idDependente.toString() || ''}
                onChange={() => onChangeDependentesDaInscricao(conjuge.id, dependente.id, dependente.nome)} />
            </div>
          ))}
        </div>
        }

        {(etapaAtual.nomeDoPasso == ETAPA_IMOVEIS && imoveis.length > 0) &&
        <div>
          {imoveis.map((imovel, index) => (
            <div key={index}>
              <p className='titulo-pergunta'>Quem é o proprietário do imóvel localizado no endereço {imovel.endereco.logradouro} ?</p>
              <CampoRadioButton
                mesmaLinha={false}
                name={imovel.id?.toString() || ''}
                label={titular.nome}
                value={imovel.id?.toString() || ''}
                checked={etapaAtual.imoveis?.find(x=> x.idPretendente == titular.id && x.idImovel == imovel.id)?.idImovel.toString() || ''}
                onChange={() => onChangeImovelDaInscricao(titular.id, imovel.id || 0, imovel.endereco.logradouro)} />
              <CampoRadioButton
                mesmaLinha={false}
                name={imovel.id?.toString() || ''}
                label={conjuge.nome}
                value={imovel.id?.toString() || ''}
                checked={etapaAtual.imoveis?.find(x=> x.idPretendente == conjuge.id && x.idImovel == imovel.id)?.idImovel.toString() || ''}
                onChange={() => onChangeImovelDaInscricao(conjuge.id, imovel.id || 0, imovel.endereco.logradouro)} />
            </div>
          ))}
        </div>
        }

        {(etapaAtual.nomeDoPasso == ETAPA_PROGRAMAS_SOCIAIS && programasSociais.length > 0) &&
        <div>
          {programasSociais.map((programaSocial, index) => (
            <div key={index}>
              <p className='titulo-pergunta'>Quem é o beneficiário do programa social {programaSocial} ?</p>
              <CampoRadioButton
                mesmaLinha={false}
                name={programaSocial.toString() || ''}
                label={titular.nome}
                value={programaSocial}
                checked={etapaAtual.programasSociais?.find(x=> x.idPretendente == titular.id && x.programaSocial == programaSocial)?.programaSocial || ''}
                onChange={() => onChangeProgramaSociaisDaInscricao(titular.id, programaSocial)} />
              <CampoRadioButton
                mesmaLinha={false}
                name={programaSocial.toString() || ''}
                label={conjuge.nome}
                value={programaSocial}
                checked={etapaAtual.programasSociais?.find(x=> x.idPretendente == conjuge.id && x.programaSocial == programaSocial)?.programaSocial || ''}
                onChange={() => onChangeProgramaSociaisDaInscricao(conjuge.id, programaSocial)} />
            </div>
          ))}
        </div>}

        {etapaAtual.nomeDoPasso == 'confirmacao' &&
        <div>
          <p className='titulo-pergunta'>Confira as informações antes de finalizar a operação</p>
          <p className='titulo-pergunta'><strong className='motivoDaSeparacao'>Motivo da separação: </strong> {motivoDaSeparacao()}</p>
      
          <div className='comparativoInscricao'>

            <div className='inscricaoQuadroComparativo'>
              <p className='titulo'>Inscrição atual</p>
              <span className='sub-titulo'>Inscrição {numeroDaInscricao}</span>          
              <hr/>
              <div>
                <span className='item-titulo'>Pretendente:</span>
                <span className='item'>{nomePretendente(STATUS_ATUAL)} </span>
                <span className='item-titulo'>Dependentes:</span>
                <div>
                  {listaDeDependentes(STATUS_ATUAL)?.map((nome, index) => (
                    <div key={index}>
                      <span className='item'>{nome}</span>
                    </div>
                  ))}
                  {listaDeDependentes(STATUS_ATUAL)?.length == 0 && (
                    <span className='item'>Nenhum dependente vinculado.</span>
                  )}  
                </div>
                <span className='item-titulo'>Imóveis:</span>
                <div>
                  {listaDeImoveis(STATUS_ATUAL)?.map((nome, index) => (
                    <div key={index}>
                      <span className='item'>{nome}</span>
                    </div>
                  ))} 
                  {listaDeImoveis(STATUS_ATUAL)?.length == 0 && (
                    <span className='item'>Nenhum imóvel vinculado.</span>
                  )}
                </div>    
                <span className='item-titulo'>Programas sociais:</span>
                <div>
                  {listaDeProgramaSociais(STATUS_ATUAL)?.map((nome, index) => (
                    <div key={index}>
                      <span className='item'>{nome}</span>
                    </div>
                  ))} 
                  {listaDeProgramaSociais(STATUS_ATUAL)?.length == 0 && (
                    <span className='item'>Nenhum programa social vinculado.</span>
                  )} 
                </div> 
              </div>
            </div>
            <div className='seta-direcao'>
              <img  src={setaParaDireita} alt='seta para a direita' height={'50px'} width={'90px'} />
            </div>
            <div className='inscricaoQuadroComparativo'>
              <p className='titulo'>Nova Inscrição</p>
              <span className='sub-titulo'>O número ainda será gerado</span>
              <hr/>
              <div>
                <span className='item-titulo'>Pretendente:</span>
                <span className='item'>{nomePretendente('novo')} </span>
                <span className='item-titulo'>Dependentes:</span>
                <div>
                  {listaDeDependentes('novo')?.map((nome, index) => (
                    <div key={index}>
                      <span className='item'>{nome}</span>
                    </div>
                  ))}
                  {listaDeDependentes('novo')?.length == 0 && (
                    <span className='item'>Nenhum dependente vinculado.</span>
                  )} 
                </div>
                <span className='item-titulo'>Imóveis:</span>
                <div>
                  {listaDeImoveis('novo')?.map((nome, index) => (
                    <div key={index}>
                      <span className='item'>{nome}</span>
                    </div>
                  ))} 
                  {listaDeImoveis('novo')?.length == 0 && (
                    <span className='item'>Nenhum imóvel vinculado.</span>
                  )} 
                </div> 
                <span className='item-titulo'>Programas sociais:</span>
                <div>
                  {listaDeProgramaSociais('novo')?.map((nome, index) => (
                    <div key={index}>
                      <span className='item'>{nome}</span>
                    </div>
                  ))} 
                  {listaDeProgramaSociais('novo')?.length == 0 && (
                    <span className='item'>Nenhum programa social vinculado.</span>
                  )} 
                </div> 
              </div>
            </div>
          </div>
        </div>}

        <div className="botoes-acoes">
          <button type="button" className="botao botao_contorno botao_medio botao_cor-secundaria" onClick={() => proximaEtapa(numeroEtapaAtual - 1, 'voltar')}>
            {etapaAtual.nomeDoPasso==ETAPA_CONFIRMACAO ? 'Mudar informações': 'Voltar'}
          </button>
          {etapaAtual.nomeDoPasso != 'confirmacao' &&
            <button type="button" className="botao botao_medio botao_cor-secundaria" disabled={checarPreenchimento()} onClick={() => proximaEtapa(numeroEtapaAtual + 1)}>
              Próximo
            </button>
          }
          {etapaAtual.nomeDoPasso == 'confirmacao' &&
            <button type="button" className="botao botao_medio botao_cor-secundaria" onClick={() => finalizarSeparacao()}>
              Finalizar
            </button>
          }
        </div> 
      </div> }    
    </>);
};
export default EtapasDaSeparacaoDeInscricao;