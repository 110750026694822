import { useEffect, useState } from 'react';
import { IndicadorPasso } from '../../componentes';
import BarraDeNavegacao from '../../componentes/BarraDeNavegacao';
import Container from '../../componentes/Container';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { buscarDadosParaHistorico } from '../../servicos/historicos';
import IDadosParaHistoricoDaInscricao from '../../interfaces/IDadosParaHistoricoDaInscricao';
import mascaras from '../../helpers/mascaras';
import Section from '../../componentes/Section';
import Historico from '../Historico/componentes/historico';

import './styles.scss';

const HistoricoDaInscricao = () => {
  const idDaInscricao = parseInt(localStorage.getItem('idDaInscricao') || '0');
  const cpf = localStorage.getItem('cpf') || '';
  const [dadosParaHistorico, setDadosParaHistorico] = useState<IDadosParaHistoricoDaInscricao>();
  const [expandidoHistoricoDeEndereco, setExpandidoHistoricoDeEndereco] = useState(false);
  const [expandidoHistoricoDeSeparacao, setExpandidoHistoricoDeSeparacao] = useState(false);
  const [expandidoHistoricoDeJuncao, setExpandidoHistoricoDeJuncao] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    obterInformacoesDaInscricao(idDaInscricao, cpf);
  }, [idDaInscricao, cpf]);

  const obterInformacoesDaInscricao = async (idDaInscricao: number, cpf:string) => {
    if (idDaInscricao) {    
      const respostaDoHistorico = await buscarDadosParaHistorico(idDaInscricao, cpf.toString());
      setDadosParaHistorico(respostaDoHistorico.data);
    }else
      voltarTelaDeListagemDeInscricoes();
  };

  const voltarTelaDeListagemDeInscricoes = () => {
    localStorage.removeItem('idDaInscricao');
    localStorage.removeItem('temPermissaoParaEditarInformacoesDeContato');
    dispatch(push('/listagem-de-inscricoes'));
  };
  return (
    <>
      <BarraDeNavegacao
        titulo='Histórico da inscrição e da pessoa'
        comBotaoVoltar={true}
        comBotaoADireita={false}
        acaoBotaoVoltar={() => dispatch(push('/listagem-de-inscricoes'))}
      />
      <Container comFundo={false} semPadding={true} tamanhoDoContainer={'medio'} >
        <Section comMargemSuperiorMedia={false} >
          <>
            <IndicadorPasso
              iconeDoPasso='fa-history'
              titulo='Histórico da inscrição e da pessoa'
              descricao='Visualize todas as alterações realizadas nessa inscrição ou nessa pessoa.' />

            <hr/>
            <div className='grade__linha'>
              <div className='grade__coluna'>
                <h2 className='titulo titulo_medio'>
                  <i className="fa-regular fa-id-card-clip cartao_cor-terciaria" aria-hidden="true"></i><strong> Dados Pessoais</strong>
                </h2>
              </div>
            </div>
            {dadosParaHistorico && 
              <div className='grade__linha grade__linha_alinhar-vertical-a-base'>
                <div className='grade__coluna grade__coluna_4'>
                  <label className='formulario__label'>
                    Nome 
                  </label>
                  <label className='formulario__descricao'>
                    {dadosParaHistorico.nome}
                  </label>
                </div>
                <div className='grade__coluna grade__coluna_4'>
                  <label className='formulario__label'>
                    CPF
                  </label>
                  <label className='formulario__descricao'>
                    {mascaras.aplicarMascaraDeCpf(dadosParaHistorico.cpf)}
                  </label>
                </div>
                <div className='grade__coluna grade__coluna_4'>
                  <label className='formulario__label'>
                    Inscrição
                  </label>
                  <label className='formulario__descricao'>
                    {dadosParaHistorico.numeroDaInscricao}
                  </label>
                </div>
              </div>
            }
          </>
        </Section>
      </Container>

      <Container comFundo={false} semPadding={true} tamanhoDoContainer={'medio'} comCabecalhoFixo={false} >
        <Section comMargemSuperiorMedia={false} >
          <>
            <div className='grade__linha grade__linha_alinhar-vertical-a-base u-cabecalho-secao' onClick={() => setExpandidoHistoricoDeEndereco(!expandidoHistoricoDeEndereco)}>
              <div className='grade__coluna grade__coluna_6'>
                <h2 className='titulo titulo_medio u-margem-mini'>
                  <i className="fa-regular fa-house-circle-check cartao_cor-terciaria" aria-hidden="true"></i><strong> Histórico de alterações de endereço</strong>
                </h2>
              </div>
              <div className='grade__coluna grade__coluna_6 u-texto-alinhado-a-direita'>
                <label className='cabecalho-do-formulario__descricao'>
                  {dadosParaHistorico?.historicoDeEndereco.length} resultados
                </label>
                <button type='button' className='botao botao_contorno botao_medio botao_icone botao_cor-cinza u-margem-esquerda-pequena'>
                  <i className={`far fa-light fa-chevron-${!expandidoHistoricoDeEndereco ? 'up' : 'down'}`}></i>
                  <span className='u-esconder-acessivel'></span>
                </button>
              </div>
            </div>
            
            <div className={`u-accordion-conteudo ${!expandidoHistoricoDeEndereco ? '': 'expandido'}`}>
              <hr/>
              <div className='u-borda-superior-cinza'>
                <br/>
                {dadosParaHistorico?.historicoDeEndereco.length ?
                  dadosParaHistorico.historicoDeEndereco.map((historico: any, index: number) =>
                    
                    <div key={index}>
                      <div className='grade__coluna colunaFlex'>
                        <div className='centro'>
                          <i className="fa-solid fa-house-circle-check" aria-hidden="true"></i>
                          <div className='linha-vertical endereco'></div>
                        </div>

                        <div>
                          <div className='grade__linha grade__linha_alinhar-vertical-a-base'>
                            <label className='formulario__label'>
                              <strong>Endereço:</strong>  {historico.endereco}
                            </label>
                          </div>
                          <div className='grade__linha grade__linha_alinhar-vertical-a-base'>
                            <label className='formulario__label'>
                              <strong>Data:</strong>  {historico.data}
                            </label>
                          </div>
                         
                        </div>
                      </div>
                      <hr/>
                    </div>
                  ) :
                  <div className="estado-vazio estado-vazio_altura-pequena estado-vazio_largura-pequena">
                    <i className="far fa-align-slash estado-vazio__icone" aria-hidden="true"></i>
                    <h2 className="estado-vazio__titulo">Nenhuma alteração encontrada</h2>
                    <p className="estado-vazio__texto">Essa inscrição não possui alterações a mostar</p>
                  </div>
                }
              </div>
            </div>
          </>
        </Section>
      </Container>
      <Container comFundo={false} semPadding={true} tamanhoDoContainer={'medio'} comCabecalhoFixo={false} >
        <Section comMargemSuperiorMedia={false} >
          <>
            <div className='grade__linha grade__linha_alinhar-vertical-a-base u-cabecalho-secao' onClick={() => setExpandidoHistoricoDeSeparacao(!expandidoHistoricoDeSeparacao)}>
              <div className='grade__coluna grade__coluna_6'>
                <h2 className='titulo titulo_medio u-margem-mini'>
                  <i className="fa-regular fa-people-arrows cartao_cor-terciaria" aria-hidden="true"></i><strong> Histórico de separações</strong>
                </h2>
              </div>
              <div className='grade__coluna grade__coluna_6 u-texto-alinhado-a-direita'>
                <label className='cabecalho-do-formulario__descricao'>
                  {dadosParaHistorico?.historicoDeSeparacao.length} resultados
                </label>
                <button type='button' className='botao botao_contorno botao_medio botao_icone botao_cor-cinza u-margem-esquerda-pequena'>
                  <i className={`far fa-light fa-chevron-${!expandidoHistoricoDeSeparacao ? 'up' : 'down'}`}></i>
                  <span className='u-esconder-acessivel'></span>
                </button>
              </div>
            </div>

            <div className={`u-accordion-conteudo ${!expandidoHistoricoDeSeparacao ? '': 'expandido'}`}>
              <hr/>
              <div className='u-borda-superior-cinza'>
                <br/>
                {dadosParaHistorico?.historicoDeSeparacao.length ?
                  dadosParaHistorico.historicoDeSeparacao.map((historico: any) =>
                    <Historico
                      historico={historico}
                      possuiDetalhes={true}
                      icone="fa-solid fa-people-arrows"
                      descricaoDaAcao="Separação" />
                  ) :
                  <div className="estado-vazio estado-vazio_altura-pequena estado-vazio_largura-pequena">
                    <i className="far fa-align-slash estado-vazio__icone" aria-hidden="true"></i>
                    <h2 className="estado-vazio__titulo">Nenhuma alteração encontrada</h2>
                    <p className="estado-vazio__texto">Essa inscrição não possui alterações a mostar</p>
                  </div>
                }
              </div>
            </div>
          </>
        </Section>
      </Container>
      <Container comFundo={false} semPadding={true} tamanhoDoContainer={'medio'} comCabecalhoFixo={false} >
        <Section comMargemSuperiorMedia={false} >
          <>
            <div className='grade__linha grade__linha_alinhar-vertical-a-base u-cabecalho-secao' onClick={() => setExpandidoHistoricoDeJuncao(!expandidoHistoricoDeJuncao)}>
              <div className='grade__coluna grade__coluna_6'>
                <h2 className='titulo titulo_medio u-margem-mini'>
                  <i className="fa-regular fa-rings-wedding cartao_cor-terciaria" aria-hidden="true"></i><strong> Histórico de junções</strong>
                </h2>
              </div>
              <div className='grade__coluna grade__coluna_6 u-texto-alinhado-a-direita'>
                <label className='cabecalho-do-formulario__descricao'>
                  {dadosParaHistorico?.historicoDeJuncao.length} resultados
                </label>
                <button type='button' className='botao botao_contorno botao_medio botao_icone botao_cor-cinza u-margem-esquerda-pequena'>
                  <i className={`far fa-light fa-chevron-${!expandidoHistoricoDeJuncao ? 'up' : 'down'}`}></i>
                  <span className='u-esconder-acessivel'></span>
                </button>
              </div>
            </div>

            <div className={`u-accordion-conteudo ${!expandidoHistoricoDeJuncao ? '': 'expandido'}`}>
              <hr/>
              <div className='u-borda-superior-cinza'>
                <br/>
                {dadosParaHistorico?.historicoDeJuncao.length ?
                  dadosParaHistorico.historicoDeJuncao.map((historico: any) =>
                    <Historico
                      historico={historico}
                      possuiDetalhes={false}
                      icone="fa-solid fa-rings-wedding"
                      descricaoDaAcao="Junção" />
                  ) :
                  <div className="estado-vazio estado-vazio_altura-pequena estado-vazio_largura-pequena">
                    <i className="far fa-align-slash estado-vazio__icone" aria-hidden="true"></i>
                    <h2 className="estado-vazio__titulo">Nenhuma alteração encontrada</h2>
                    <p className="estado-vazio__texto">Essa inscrição não possui alterações a mostar</p>
                  </div>
                }
              </div>
            </div>
          </>
        </Section>
      </Container>
    </>
  );
};

export default HistoricoDaInscricao;