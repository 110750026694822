import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BotaoComTexto, Mensagem, GerenciadorDeMensagem } from '@digix-ui/componentes';
import CampoDeTexto from '../CampoDeTexto';
import { detectarCapsLock, detectarLetrasMaiusculas, } from '../../helpers/deteccaoDeEventos';
import { apiDaInscricao } from '../../servicos/axios';
import { endAjaxCall } from '../../actions/requisicoesAjax.actions';

const ModalDeAlteracaoDeSenha = ({ deveExibirDialogo, fecharDialogo }) => {
  const [senhaAtual, setarSenha] = useState('');
  const [novaSenha, setarNovaSenha] = useState('');
  const [confirmacaoDaNovaSenha, setarConfirmacaoDaNovaSenha] = useState('');
  const [erros, setarErros] = useState('');
  const [capsLockEstaAtivo, setarCapsLockAtivo] = useState(false);
  const [mensagemDeErro, setarMensagemDeErro] = useState('');

  const notificarCapsLockAtivo = (estaAtivo) => {
    setarCapsLockAtivo(estaAtivo);
  };

  const dispatch = useDispatch();

  const validarSenha = () => {
    if (novaSenha != confirmacaoDaNovaSenha) {
      setarErros('As senhas não conferem!');
    } else {
      limparMensagemDeErro();
    }
  };

  const onChangeNovaSenha = (evento) => {
    const texto = evento.target.value;
    setarNovaSenha(texto);
  };

  const onChangeConfirmacaoDaNovaSenha = (evento) => {
    setarConfirmacaoDaNovaSenha(evento.target.value);
  };

  const limparMensagemDeErro = () => {
    setarErros('');
  };

  let podeAlterarSenha = !!senhaAtual && !!novaSenha && novaSenha === confirmacaoDaNovaSenha;

  const parametros = {
    SenhaAtual: senhaAtual,
    NovaSenha: novaSenha,
    ConfirmacaoDaNovaSenha: confirmacaoDaNovaSenha,
  };

  const alterarSenha = async (e) => {
    e.preventDefault();
    validarSenha();
    try {
      await apiDaInscricao().post('/usuario/alterarSenha', parametros);
      setarSenha('');
      setarNovaSenha('');
      setarConfirmacaoDaNovaSenha('');
      dispatch(endAjaxCall());
      fecharDialogo();

      GerenciadorDeMensagem.criarMensagem({
        tipo: 'sucesso',
        icone: 'far fa-check-circle',
        titulo: 'Tudo certo!',
        texto: 'Sua senha foi alterada com sucesso.',
      });
    } catch (error) {
      setarMensagemDeErro(error.response.data.mensagens[0]);
    }
  };

  return (
    <>
      <div
        role="dialog"
        aria-label="Diálogo"
        className={`dialogo dialogo_mini ${deveExibirDialogo ? 'dialogo_ativo' : ''
        }`}
      >
        <div className="dialogo__container">
          <div className="dialogo__cabecalho">
            <h2 className="dialogo__titulo">Alterar Senha</h2>
            <p className="dialogo__subtitulo"></p>
          </div>
          <div className="dialogo__corpo">
            {mensagemDeErro !== '' && (
              <Mensagem
                tipo="erro"
                titulo={mensagemDeErro}
                icone="far fa-times-circle fa-lg u-margem-superior-mini"
                className={'u-sem-margem u-padding-mini'}
              />
            )}
            <CampoDeTexto
              label="Senha atual"
              name="senha"
              tipo="password"
              value={senhaAtual}
              capsLockAtivo={capsLockEstaAtivo}
              onKeyPress={(e) =>
                detectarLetrasMaiusculas(e, notificarCapsLockAtivo)
              }
              onKeyDown={(e) => detectarCapsLock(e, notificarCapsLockAtivo)}
              onChange={(e) => setarSenha(e.target.value)}
            />
            <CampoDeTexto
              label="Nova senha"
              name="novaSenha"
              tipo="password"
              value={novaSenha}
              capsLockAtivo={capsLockEstaAtivo}
              onKeyPress={(e) =>
                detectarLetrasMaiusculas(e, notificarCapsLockAtivo)
              }
              onKeyDown={(e) => detectarCapsLock(e, notificarCapsLockAtivo)}
              onChange={(e) => onChangeNovaSenha(e)}
              onBlur={() => validarSenha()}
            />
            <CampoDeTexto
              label="Confirmar nova senha"
              name="confirmacaoDaNovaSenha"
              tipo="password"
              value={confirmacaoDaNovaSenha}
              capsLockAtivo={capsLockEstaAtivo}
              onKeyPress={(e) =>
                detectarLetrasMaiusculas(e, notificarCapsLockAtivo)
              }
              onKeyDown={(e) => detectarCapsLock(e, notificarCapsLockAtivo)}
              onChange={(e) => onChangeConfirmacaoDaNovaSenha(e)}
              onBlur={() => validarSenha()}
              onFocus={() => limparMensagemDeErro()}
              erro={erros}
            />
            <div className="dialogo__rodape">
              <BotaoComTexto
                tamanho="mini"
                cor="sucesso"
                habilitado={podeAlterarSenha}
                tipo="button"
                onClick={(e) => alterarSenha(e)}
              >
                Alterar Senha
              </BotaoComTexto>
              <BotaoComTexto
                tamanho="mini"
                cor="perigo"
                habilitado={true}
                tipo="button"
                onClick={() => fecharDialogo()}
              >
                Cancelar
              </BotaoComTexto>
            </div>
          </div>

          <button
            className="dialogo__botao-fechar"
            type="button"
            aria-label="Fechar diálogo"
            onClick={() => fecharDialogo()}
          >
            <i className="far fa-times" aria-label="hidden"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default ModalDeAlteracaoDeSenha;