import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { Header, IndicadorPasso } from '../../componentes';
import FormularioPretendente, { validacao } from '../../componentes/FormularioPretendente';
import AvisoInconsistencia from '../../componentes/AvisoInconsistencia';
import Container from '../../componentes/Container';
import { GerenciadorDeMensagem } from '@digix-ui/componentes';
import '../../estilos/estilos.scss';
import { manterSomenteNumeros } from '../../helpers/mascaras';
import { juntarInscricoes, obterInformacoesNoServicoDeConsultaDeCpf, obterPorCpf, validarJuncao, verificarSePermiteCadastro } from '../../servicos/pretendente';
import { verificarSeEhAmbienteDeProducao } from '../../helpers/verificadorDeAmbientes';
import acessoFeitoNoResumoDaInscricao from '../../helpers/verificadorDeAcessoPeloResumo';
import { apiDaInscricaoSemLoading } from '../../servicos/axios';
import { useDispatch, useSelector } from 'react-redux';
import IPretendente from '../../interfaces/IPretendente';
import ICid from '../../interfaces/ICid';
import IProfissao from '../../interfaces/IProfissao';
import initialState from '../../reducers/initialState.reducer';
import { AxiosError } from 'axios';
import { IDocumento } from '../../interfaces/IDocumento';
import IContato from '../../interfaces/IContato';
import IDeficiencia from '../../interfaces/IDeficiencia';
import { IPretendenteValidacaoErroCampos } from '../../interfaces/IPretendenteValidacao';
import BarraDeNavegacao from '../../componentes/BarraDeNavegacao';
import IEtnia from '../../interfaces/IEtnia';
import IGrupoEtnico from '../../interfaces/IGrupoEtnico';
import IRegimeDeCasamento from '../../interfaces/IRegimeDeCasamento';
import * as servicoConjuge from '../../servicos/conjuge';
import { salvarConjugeSucesso } from '../../actions/dadosConjuge.actions';
import IGrauDeInstrucao from '../../interfaces/IGrauDeInstrucao';
import IMoradia from '../../interfaces/IMoradia';
import IInformacaoJuncaoDeInscricao from '../../interfaces/IInformacaoJuncaoDeInscricao';
import { deveCarregarInscricaoNaMemoria } from '../../actions/deveCarregarInscricaoNaMemoria.actions';
import IEmancipacao from '../../interfaces/IEmancipacao';
import { ehIdadeQuePermiteEmancipacao } from '../../helpers/verificadorDeData';
import IInformacoesDoServicoDeConsultaDeCpf from '../../interfaces/IInformacoesDoServicoDeConsultaDeCpf';
import ITipoDeDeficiencia from '../../interfaces/ITipoDeDeficiencia';
import ICidade from '../../interfaces/ICidade';

interface ISituacaoDoCpf {
  permiteCadastro: boolean,
  mensagem?: string
}

interface IDadosDeErro {
  mensagens: string[];
}

const DadosConjuge = () => {

  const iniciarPretendente = () => {
    if (!dadosConjuge.id) {
      dadosConjuge.sexo = '';
      dadosConjuge.estadoCivil = dadosTitular.estadoCivil;
      dadosConjuge.uniaoEstavel = dadosTitular.uniaoEstavel;
      dadosConjuge.dataDoCasamento = dadosTitular.dataDoCasamento;
      dadosConjuge.regimeDeCasamento = dadosTitular.regimeDeCasamento;
      //@ts-ignore
      dadosConjuge.dataDeNascimento = login.dataDeNascimento;
      dadosConjuge.ehEstrangeiro = false;
      dadosConjuge.deficiencias = [];
      dadosConjuge.contato = {} as IContato;
    }
    return dadosConjuge;
  };

  const inscricao = useSelector((state: typeof initialState) => state.inscricao);
  const etnias = useSelector((state: typeof initialState) => state.etnias) as IEtnia[];
  const regimesDeCasamento = useSelector((state: typeof initialState) => state.regimesDeCasamento) as IRegimeDeCasamento[];
  const dadosConjuge = useSelector((state: typeof initialState) => state.dadosConjuge) as IPretendente;
  const dadosTitular = useSelector((state: typeof initialState) => state.dadosTitular) as IPretendente;
  const idInscricao = useSelector((state: typeof initialState) => state.inscricao.id) as number;
  const login = useSelector((state: typeof initialState) => state.login);
  const [dadosPretendente, setDadosPretendente] = useState<IPretendente>(iniciarPretendente);
  const ehAcessoAdministrativo = useSelector((state: typeof initialState) => state.ehAcessoAdministrativo) as boolean;
  const cidades = useSelector((state: typeof initialState) => state.cidades);
  const profissoes = (useSelector((state: typeof initialState) => state.profissoes) as IProfissao[]).filter(profissao => profissao.nome != 'NÃO SE APLICA');
  const grauDeInstrucoes = useSelector((state: typeof initialState) => state.grauDeInstrucoes) as IGrauDeInstrucao[];
  const ehNovaInscricao = useSelector((state: typeof initialState) => state.ehNovaInscricao) as boolean;
  const [tentandoSalvar, setTentandoSalvar] = useState<boolean>(false);
  const [possuiDeficiencia, setPossuiDeficiencia] = useState<boolean | undefined>();
  const [possuiDoencaCronicaIncapacitante, setPossuiDoencaCronicaIncapacitante] = useState<boolean | undefined>();
  const [possuiDoencaCronicaDegenerativa, setPossuiDoencaCronicaDegenerativa] = useState<boolean | undefined>();
  const [possuiDoencaCronicaCancer, setPossuiDoencaCronicaCancer] = useState<boolean | undefined>();
  const [checkboxDoNomeSocialEstaMarcado, setCheckboxDoNomeSocialEstaMarcado] = useState<boolean>(dadosPretendente.nomeSocial === undefined ? false : dadosPretendente.nomeSocial.trim() === '' ? false : true);
  const [erros, setErros] = useState<IPretendenteValidacaoErroCampos | undefined>();
  const [situacaoDoCpf, setSituacaoDoCpf] = useState<ISituacaoDoCpf>(dadosConjuge.id ? { permiteCadastro: true } : { permiteCadastro: false });
  const dispatch = useDispatch();
  const moradia = useSelector((state: typeof initialState) => state.moradia) as IMoradia;
  const habilitarCampoCpf = dadosPretendente.id ? false : true;
  const [exibirDialogoDeJuncaoDeInscricao, setExibirDialogoDeJuncaoDeInscricao] = useState(false);
  const [informacaoParaJuncaoDeInscricao, setInformacaoParaJuncaoDeInscricao] = useState<IInformacaoJuncaoDeInscricao | undefined>();
  const [realizandoJuncaoDeInscricao, setRealizandoJuncaoDeInscricao] = useState<boolean>(false);
  const ehFormularioSomenteLeitura = useSelector((state: typeof initialState) => state.ehFormularioSomenteLeitura) as boolean;
  const estaEmancipado = !!dadosConjuge?.emancipacao?.tipo;
  const ehIdadeQuePermiteEmancipar = ehIdadeQuePermiteEmancipacao(dadosPretendente.dataDeNascimento);
  const exibirCamposDeEmancipacao = ehAcessoAdministrativo && (estaEmancipado || ehIdadeQuePermiteEmancipar);
  const tiposDeDeficiencias = useSelector((state: typeof initialState) => state.tiposDeDeficiencia) as ITipoDeDeficiencia[];
  const marcarPossuiDeficiencia = possuiDeficiencia != null ? possuiDeficiencia : !!dadosPretendente.deficiencias && dadosPretendente.deficiencias.length > 0;

  const inscricaoEstaVazioNaMemoria = Object.keys(inscricao).length == 1;
  useEffect(() => {
    if (inscricaoEstaVazioNaMemoria && !ehNovaInscricao)
      dispatch(push('/menu-inscricao'));
  }, []);

  const indicadorPasso = <IndicadorPasso
    iconeDoPasso='fa-user-friends'
    titulo='Cônjuge'
    descricao='Preencha todos os campos abaixo. Todos os dados deverão ser comprovados posteriormente.' />;

  const estadoCivilDesabilitado = ({ estadoCivil }: IPretendente) => estadoCivil && estadoCivil.toUpperCase() === 'CASADO';

  const uniaoEstavelDesabilitado = ({ uniaoEstavel }: IPretendente) => uniaoEstavel;

  const cpfDesabilitado = ({ cpf }: IPretendente) => !!cpf;

  const estadoCivilCasadoDesabilitado = ({ uniaoEstavel, estadoCivil }: IPretendente) => uniaoEstavel || estadoCivil?.toUpperCase() === 'CASADO';

  const camposDesabilitados = [
    estadoCivilCasadoDesabilitado(dadosTitular) ? 'estadoCivilCasado' : '', 'uniaoEstavel', 'cpf',
    estadoCivilDesabilitado(dadosTitular) ? 'estadoCivil' : '',
    uniaoEstavelDesabilitado(dadosTitular) ? 'uniaoEstavel' : '',
    cpfDesabilitado(dadosConjuge) ? 'cpf' : ''
  ];

  const obterEstadoInicialDoFormulario = () => {
    setDadosPretendente({
      ...dadosPretendente,
      estadoCivil: dadosTitular.estadoCivil,
      uniaoEstavel: dadosTitular.uniaoEstavel,
      documentos: {},
      ehEstrangeiro: false
    });

    setPossuiDeficiencia(false);
    setPossuiDoencaCronicaIncapacitante(false);
    setPossuiDoencaCronicaDegenerativa(false);
    setPossuiDoencaCronicaCancer(false);

  };

  const atualizarSituacaoDoCpf = (situacaoDoCpf: ISituacaoDoCpf) => {
    const ehAmbienteDeProducao = verificarSeEhAmbienteDeProducao();

    setSituacaoDoCpf(situacaoDoCpf);
    if (ehAmbienteDeProducao)
      obterEstadoInicialDoFormulario();
  };

  const onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const erro: IPretendenteValidacaoErroCampos = validacao.validarCampo(dadosPretendente,
      name, value, ehAcessoAdministrativo, possuiDeficiencia, possuiDoencaCronicaIncapacitante,
      possuiDoencaCronicaDegenerativa, possuiDoencaCronicaCancer,
      checkboxDoNomeSocialEstaMarcado);
    if (erro)
      //@ts-ignore
      setErros({ ...erros, [name]: erro });
  };

  const removerErro = (...campos: string[]): void => {
    if (campos == undefined || erros == undefined) return;

    //@ts-ignore
    campos.forEach((campo: string) => erros[campo] = undefined);
    setErros(erros);
  };

  const onFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    removerErro(event.target.name);
  };

  const atualizarDocumento = (documentos: IDocumento) => {
    setDadosPretendente({ ...dadosPretendente, documentos });
  };

  const atualizarContato = (contato: IContato) => {
    if (!contato.telefoneParaRecado)
      contato.nomeDoResponsavel = '';
    setDadosPretendente({ ...dadosPretendente, contato: contato });
  };

  const atualizar = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const valor: string | boolean = mapearValor(value);
    if (name == 'situacaoNoMercadoDeTrabalho' && valor == 'Desempregado')
      setDadosPretendente({ ...dadosPretendente, [name]: valor, valorDaRenda: 0 });
    else
      setDadosPretendente({ ...dadosPretendente, [name]: valor });
    removerErro(name);
  };

  const atualizarNacionalidade = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (value === 'true')
      atualizarCampoDeEstrangeiroLimpandoCamposDeNaoEstrageiro(name, value);
    else
      limparCieAtualizandoCampo(name, value);

    removerErro(name);
  };

  const atualizarCampoDeEstrangeiroLimpandoCamposDeNaoEstrageiro = (name: string, value: string) => {
    const valor: string | boolean = mapearValor(value);
    setDadosPretendente({
      ...dadosPretendente,
      [name]: valor,
      pais: undefined,
      documentos: {
        ...dadosPretendente.documentos,
        rg: undefined
      },
      moraNaAldeia: undefined,
      grupoEtnico: undefined,
      etnia: undefined,
      cidadeDeNaturalidade: undefined
    });
  };

  const limparCieAtualizandoCampo = (name: string, value: string) => {
    const valor: string | boolean = mapearValor(value);
    setDadosPretendente({
      ...dadosPretendente,
      [name]: valor,
      pais: undefined,
      documentos: {
        ...dadosPretendente.documentos
        , cedulaDeIdentidadeDoEstrangeiro: undefined
      }
    });
  };

  const validarFormulario = () => {
    setDadosPretendente({ ...dadosPretendente });
    const errosValidacao = validacao.validarFormulario(dadosPretendente, ehAcessoAdministrativo,
      marcarPossuiDeficiencia, possuiDoencaCronicaIncapacitante, possuiDoencaCronicaDegenerativa,
      possuiDoencaCronicaCancer, checkboxDoNomeSocialEstaMarcado,
      exibirCamposDeEmancipacao);

    setErros(errosValidacao);

    return !Object.keys(errosValidacao).length;
  };

  const salvar = async () => {

    //@ts-ignore
    const { data: inscricao } = await servicoConjuge.salvarConjuge(dadosPretendente, ehAcessoAdministrativo ? dadosPretendente.cpf : login.cpf, idInscricao, ehAcessoAdministrativo);

    dispatch(salvarConjugeSucesso({ ...dadosPretendente, id: inscricao.conjuge.id }));
    if (!ehNovaInscricao)
      dispatch(push('/menu-inscricao'));
    else
      dispatch(push('/dependentes'));

    GerenciadorDeMensagem.criarMensagem({
      tipo: 'sucesso',
      icone: 'far fa-check-circle',
      titulo: 'Tudo certo!',
      texto: 'Dados do titular cadastrados com sucesso.'
    });
  };

  const editar = async () => {
    await servicoConjuge.editarConjuge(dadosPretendente, idInscricao, ehAcessoAdministrativo);
    dispatch(salvarConjugeSucesso(dadosPretendente));

    if (acessoFeitoNoResumoDaInscricao())
      dispatch(push('/resumo-da-inscricao'));
    else {
      ehNovaInscricao
        ? dispatch(push('/dependentes'))
        : dispatch(push('/menu-inscricao'));
    }

    GerenciadorDeMensagem.criarMensagem({
      tipo: 'sucesso',
      icone: 'far fa-check-circle',
      titulo: 'Tudo certo!',
      texto: 'Dados do cônjuge atualizados com sucesso.'
    });
  };

  const posicionarTela = () => {
    if (tentandoSalvar) {
      let campoComErro: HTMLElement | null = document.querySelector('.formulario__mensagem_erro');

      if (campoComErro) {
        const alturaDoCabecalhoDaPagina = 115;
        campoComErro.scrollIntoView({ behavior: 'smooth' });
        window.scrollBy(0, -alturaDoCabecalhoDaPagina);
      }
      setTentandoSalvar(false);
    }
  };

  const controlarValorDoCampoNomeSocial = () => {
    setCheckboxDoNomeSocialEstaMarcado(!checkboxDoNomeSocialEstaMarcado);
    setDadosPretendente({ ...dadosPretendente, nomeSocial: undefined });
  };

  const salvarConjuge = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setTentandoSalvar(true);

    if (!validarEstadoCivilDoConjuge())
      return;

    if (!validarFormulario()) {
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: 'Formulário inválido. Por favor corrija os campos obrigatórios.'
      });
      posicionarTela();
      return;
    }

    dadosPretendente.id ? await editar() : await salvar();
  };

  const validarEstadoCivilDoConjuge = () => {
    const titularEstaEmUniaoEstavel = dadosTitular.uniaoEstavel;
    const conjugeAlterouParaEstadoCivilCasado = dadosPretendente.estadoCivil == 'casado';

    if (titularEstaEmUniaoEstavel && conjugeAlterouParaEstadoCivilCasado) {
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: 'Formulário inválido. Só é permitido alterar o estado civil para casado no formulário do titular.'
      });
      return false;
    }
    return true;
  };

  const atualizarSexo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const ehDoSexoMasculino = value.toLowerCase() === 'masculino';

    let ehMulherVitimaDeViolencia = ehDoSexoMasculino ? undefined : dadosPretendente.ehMulherVitimaDeViolenciaDomestica;
    let mulherChefeDeFamilia = ehDoSexoMasculino ? false : dadosPretendente.mulherChefeDeFamilia;

    setDadosPretendente({
      ...dadosPretendente,
      [name]: value,
      ehMulherVitimaDeViolenciaDomestica: ehMulherVitimaDeViolencia,
      mulherChefeDeFamilia
    });

    if (ehDoSexoMasculino) {
      removerErro('erroEhMulherVitimaDeViolenciaDomestica', 'erroMulherChefeDeFamilia');
    }
    removerErro('sexo');
  };

  const limparDeficiencias = () => {
    setDadosPretendente({
      ...dadosPretendente,
      deficiencias: []
    });
  };

  const onChangeDeficiencia = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const valor = value == 'true';

    setPossuiDeficiencia(valor);
    if (!valor) {
      limparDeficiencias();
      removerErro(name);
    }
  };

  const onChangeDoencaCronica = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const valor = value == 'true';

    if (name == 'possuiDoencaCronicaIncapacitante') {
      setPossuiDoencaCronicaIncapacitante(valor);
      if (!valor) {
        setDadosPretendente({
          ...dadosPretendente,
          cidDaDoencaCronicaIncapacitante: undefined
        });
        removerErro(name);
      }
    } else if (name == 'possuiDoencaCronicaDegenerativa') {
      setPossuiDoencaCronicaDegenerativa(valor);
      if (!valor) {
        setDadosPretendente({
          ...dadosPretendente,
          cidDaDoencaCronicaDegenerativa: undefined
        });
        removerErro(name);
      }
    } else if (name == 'possuiDoencaCronicaCancer') {
      setPossuiDoencaCronicaCancer(valor);
      if (!valor) {
        setDadosPretendente({
          ...dadosPretendente,
          cidDaDoencaCronicaCancer: undefined
        });
        removerErro(name);
      }
    }

  };

  const atualizarProfissao = (profissaoSelecionada?: IProfissao) => {
    setDadosPretendente({
      ...dadosPretendente,
      profissao: !profissaoSelecionada ? undefined : profissaoSelecionada,
      atuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica: undefined,
      aceitaParticiparDoHabiteSeguro: undefined
    });
    removerErro('profissao');
  };

  const atualizarGrauDeInstrucao = (grauDeInstrucaoSelecionado?: IGrauDeInstrucao) => {
    setDadosPretendente({
      ...dadosPretendente,
      grauDeInstrucao: !grauDeInstrucaoSelecionado ? undefined : grauDeInstrucaoSelecionado
    });
    removerErro('grauDeInstrucao');
  };

  const limparParticipacaoNoProgramaHabiteSeguro = () => {
    setDadosPretendente({
      ...dadosPretendente,
      atuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica: false,
      aceitaParticiparDoHabiteSeguro: undefined
    });
  };

  const buscarCid = async (cid: string) => {
    const numeroMinimoDeCaracteresParaRealizarBusca = 3;
    if (!cid || cid.length < numeroMinimoDeCaracteresParaRealizarBusca)
      return { options: [] };

    const { data } = await apiDaInscricaoSemLoading().get(`/cid/buscar?cid=${cid}`);
    const retorno = {
      options: data.map((cid: any) => {
        return { value: cid.id, label: `${cid.codigo} - ${cid.descricao}` };
      })
    };
    return retorno;
  };

  const adicionarDeficiencia = (deficiencia: IDeficiencia) => {
    const deficiencias = dadosPretendente.deficiencias || [];
    const deficienciasComCid = deficiencias.filter(d => d.cid);
    if (deficiencias.some(d => d.cid?.id === deficiencia.cid.id)) {
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: 'Já existe uma deficiência com esse CID.'
      });
      return;
    }

    setDadosPretendente({
      ...dadosPretendente,
      deficiencias: [
        ...deficienciasComCid,
        deficiencia
      ]
    });
    removerErro('deficiencias');
  };

  const removerDeficiencia = (cid: ICid) => {
    let deficiencias = dadosPretendente.deficiencias || [];
    deficiencias = deficiencias.filter(d => d.cid !== cid);

    setDadosPretendente({
      ...dadosPretendente,
      deficiencias
    });
  };

  const atualizarDoencaCronica = (tipoDeDoenca: string, doencaCronica: number, nomeDaDoencaCronica: string) => {
    var doenca = {
      id: doencaCronica,
      codigo: nomeDaDoencaCronica.split(' - ')[0],
      descricao: nomeDaDoencaCronica
    };

    if (tipoDeDoenca == 'Incapacitante') {
      setDadosPretendente({ ...dadosPretendente, cidDaDoencaCronicaIncapacitante: doenca });
    }
    else if (tipoDeDoenca == 'Degenerativa') {
      setDadosPretendente({ ...dadosPretendente, cidDaDoencaCronicaDegenerativa: doenca });
    }
    else if (tipoDeDoenca == 'Cancer') {
      setDadosPretendente({ ...dadosPretendente, cidDaDoencaCronicaCancer: doenca });
    }

    removerErro('doencaCronica' + tipoDeDoenca);
  };

  const removerDoencaCronica = (tipoDeDoenca: string) => {
    if (tipoDeDoenca == 'Incapacitante') {
      setDadosPretendente({ ...dadosPretendente, cidDaDoencaCronicaIncapacitante: undefined });
    }
    else if (tipoDeDoenca == 'Degenerativa') {
      setDadosPretendente({ ...dadosPretendente, cidDaDoencaCronicaDegenerativa: undefined });
    }
    else if (tipoDeDoenca == 'Cancer') {
      setDadosPretendente({ ...dadosPretendente, cidDaDoencaCronicaCancer: undefined });
    }
    removerErro('doencaCronica' + tipoDeDoenca);
  };

  const obterInformacoesDoCpf = async (cpf: string) => {
    try {
      return await obterInformacoesNoServicoDeConsultaDeCpf(cpf);
    } catch (error) {
      const erroTipado = error as AxiosError;
      const { mensagens } = erroTipado.response?.data as IDadosDeErro;
      const mensagem = mensagens?.join('\n');

      if (mensagem)
        GerenciadorDeMensagem.criarMensagem({
          tipo: 'erro',
          icone: 'far fa-times-circle',
          titulo: 'Identificamos um erro:',
          texto: mensagem
        });
    }
  };

  const verificarCpfParaAcessoAdministrativo = async (cpf: string) => {
    const respostaDaVerificacaoDeJuncao = await validarJuncao(idInscricao, cpf) as IInformacaoJuncaoDeInscricao;
    const permiteCadastro = !respostaDaVerificacaoDeJuncao.possuiInscricao;
    const ehAmbienteDeProducao = verificarSeEhAmbienteDeProducao();

    if (permiteCadastro) {
      const informacoes = ehAmbienteDeProducao ? await obterInformacoesDoCpf(cpf) : {} as IInformacoesDoServicoDeConsultaDeCpf;
      setSituacaoDoCpf({ permiteCadastro });
      setDadosPretendente({ ...dadosPretendente, cpf, nome: informacoes?.nome || '', dataDeNascimento: informacoes?.dataDeNascimento });
      return;
    }
    setExibirDialogoDeJuncaoDeInscricao(respostaDaVerificacaoDeJuncao.possuiInscricao);
    setInformacaoParaJuncaoDeInscricao(respostaDaVerificacaoDeJuncao);
  };

  const verificarCpfParaAcessoPublico = async (cpf: string) => {
    const respostaDaVerificacao = await verificarSePermiteCadastro(idInscricao, cpf);
    const permiteCadastro = respostaDaVerificacao;
    const ehAmbienteDeProducao = verificarSeEhAmbienteDeProducao();

    if (!permiteCadastro) {
      const situacaoDoCpf = {
        permiteCadastro,
        mensagem: 'Este CPF já possui uma inscrição cadastrada e não pode ser adicionado como cônjuge. Entre em contato com um local de atendimento para maiores informações.'
      };
      atualizarSituacaoDoCpf(situacaoDoCpf);
      return;
    }

    if (!ehAmbienteDeProducao) {
      setSituacaoDoCpf({ permiteCadastro });
      return;
    }

    const informacoes = await obterInformacoesDoCpf(cpf);
    if (!informacoes){
      atualizarSituacaoDoCpf({ permiteCadastro: false });
      return;
    }

    setSituacaoDoCpf({ permiteCadastro });
    setDadosPretendente({ ...dadosPretendente, cpf, nome: informacoes?.nome || '', dataDeNascimento: informacoes?.dataDeNascimento });
  };

  const verificarSeCpfEstaDisponivelParaInscricao = async (cpf: string) => {
    if (ehAcessoAdministrativo) {
      verificarCpfParaAcessoAdministrativo(cpf);
      return;
    }
    verificarCpfParaAcessoPublico(cpf);
  };

  const atualizarCpf = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setDadosPretendente({ ...dadosPretendente, cpf: value });

    let cpfSemMascara = manterSomenteNumeros(value);
    let cpfTem11Digitos = cpfSemMascara.length == 11;
    if (cpfTem11Digitos) {
      verificarSeCpfEstaDisponivelParaInscricao(cpfSemMascara);
    }
  };

  const mapearValor = (valor: string) => {
    return valor === 'true' ? true : (valor === 'false' ? false : valor);
  };

  const atualizarPais = (sigla?: string, nome?: string) => {
    setDadosPretendente({
      ...dadosPretendente,
      pais: (sigla && nome) ? { sigla, nome } : undefined
    });
    removerErro('pais');
  };

  const nomeCidade = () => {
    //@ts-ignore
    return moradia?.endereco?.cidade?.nome ? moradia.endereco.cidade.nome : login.cidade ? cidades.filter((c: any) => c.id == login.cidade)[0]?.nome || '' : '';
  };

  const obterGruposEtnicos = async (etnia?: IEtnia) => {
    if (!etnia?.ehIndigena)
      setDadosPretendente({ ...dadosPretendente, etnia: etnia, grupoEtnico: undefined, moraNaAldeia: undefined });
    else
      setDadosPretendente({ ...dadosPretendente, etnia: etnia });

    removerErro('etnia');
  };

  const onChangeGrupoEtnico = (grupoEtnico?: IGrupoEtnico) => {
    setDadosPretendente({ ...dadosPretendente, grupoEtnico: grupoEtnico });
    removerErro('grupoEtnico');
  };

  const onChangeRegimeDeCasamento = (regimeDeCasamento?: IRegimeDeCasamento) => {
    setDadosPretendente({ ...dadosPretendente, regimeDeCasamento: regimeDeCasamento });
    removerErro('regimeDeCasamento');
  };

  const onChangeEstadoCivil = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value == 'separadojudicialmente')
      setDadosPretendente({ ...dadosPretendente, estadoCivil: value, dataDoCasamento: undefined, regimeDeCasamento: undefined, uniaoEstavel: false });
    else if (value != 'casado') {
      setDadosPretendente({ ...dadosPretendente, estadoCivil: value, dataDoCasamento: undefined, regimeDeCasamento: undefined, uniaoEstavel: dadosTitular.uniaoEstavel });
    }
    else
      setDadosPretendente({ ...dadosPretendente, estadoCivil: value, uniaoEstavel: false });

    removerErro('estadoCivil');
  };

  const cancelarJuncaoDaInscricao = () => {
    setExibirDialogoDeJuncaoDeInscricao(false);
    setDadosPretendente(iniciarPretendente);
  };

  const confirmarJuncaoDaInscricao = async () => {
    setExibirDialogoDeJuncaoDeInscricao(false);
    setRealizandoJuncaoDeInscricao(true);

    const pretendente = await obterPorCpf(informacaoParaJuncaoDeInscricao?.cpf || '');
    if (pretendente) {
      const situacaoDoCpf = { permiteCadastro: true };
      setSituacaoDoCpf(situacaoDoCpf);
      setDadosPretendente(pretendente);
    }
  };

  const juntarInscricao = async () => {
    await juntarInscricoes(informacaoParaJuncaoDeInscricao?.idInscricaoOrigem || 0, inscricao.id, dadosPretendente);
    dispatch(deveCarregarInscricaoNaMemoria(true));
    dispatch(push('/menu-inscricao'));

    GerenciadorDeMensagem.criarMensagem({
      tipo: 'sucesso',
      icone: 'far fa-check-circle',
      titulo: 'Concluído!',
      texto: 'Junção de inscrição realizada com sucesso!'
    });

  };

  const onChangeEmancipacao = (emancipacao: IEmancipacao, camposParaRemoverErro?: string[]) => {
    setDadosPretendente({ ...dadosPretendente, emancipacao });
    if (camposParaRemoverErro)
      removerErro(...camposParaRemoverErro);
  };

  const onChangeCidadeDeNaturalidade = (cidadeDeNaturalidade: ICidade) => {
    setDadosPretendente({ ...dadosPretendente, cidadeDeNaturalidade: cidadeDeNaturalidade });
    removerErro('cidadeDeNaturalidade');
  };

  return (
    <>
      {(!inscricaoEstaVazioNaMemoria || ehNovaInscricao) &&
        <div className="pagina-pretendente">
          {!ehAcessoAdministrativo && (
            <Header subTitulo='Cadastro - Dados do cônjuge' comBotaoVoltar={true} urlBotaoVoltar={'/menu-inscricao'} />
          )}
          {ehAcessoAdministrativo && (
            <BarraDeNavegacao
              titulo="Cadastro - Dados do cônjuge"
              comBotaoVoltar={true}
              acaoBotaoVoltar={() => dispatch(push('/menu-inscricao'))}
              comBotaoADireita={true}
              textoBotaoADireita="Menu de inscrição"
              iconeBotaoADireita={false}
              acaoBotaoADireita={() => dispatch(push('/menu-inscricao'))}
            />
          )}

          <div className="pagina-pretendente__container">
            <Container comFundo={false} semPadding={true} tamanhoDoContainer={''} >
              <AvisoInconsistencia
                visivel={situacaoDoCpf.mensagem}
                mensagemDeAlerta={situacaoDoCpf.mensagem} />
              <FormularioPretendente
                onChangeCpf={atualizarCpf}
                dadosPretendente={dadosPretendente}
                camposDesabilitados={camposDesabilitados}
                possuiDoencaCronicaIncapacitante={possuiDoencaCronicaIncapacitante != null ? possuiDoencaCronicaIncapacitante : !!dadosPretendente.cidDaDoencaCronicaIncapacitante}
                possuiDoencaCronicaDegenerativa={possuiDoencaCronicaDegenerativa != null ? possuiDoencaCronicaDegenerativa : !!dadosPretendente.cidDaDoencaCronicaDegenerativa}
                possuiDoencaCronicaCancer={possuiDoencaCronicaCancer != null ? possuiDoencaCronicaCancer : !!dadosPretendente.cidDaDoencaCronicaCancer}
                possuiDeficiencia={marcarPossuiDeficiencia}
                erros={erros}
                onChange={atualizar}
                onChangeDocumento={atualizarDocumento}
                onChangeContato={atualizarContato}
                onChangeNacionalidade={atualizarNacionalidade}
                onBlur={onBlur}
                onChangeNomeSocial={controlarValorDoCampoNomeSocial}
                checkboxDoNomeSocialEstaMarcado={checkboxDoNomeSocialEstaMarcado}
                onFocus={onFocus}
                onChangeDeficiencia={onChangeDeficiencia}
                onChangeDoencaCronica={onChangeDoencaCronica}
                onChangeSexo={atualizarSexo}
                eventosDeficiencia={{ adicionarDeficiencia, removerDeficiencia, buscarCid }}
                eventosDoencaCronica={{ atualizarDoencaCronica, buscarCid, removerDoencaCronica }}
                profissoes={profissoes}
                grauDeInstrucoes={grauDeInstrucoes}
                nomeDaCidade={nomeCidade()}
                onClick={salvarConjuge}
                indicadorPasso={indicadorPasso}
                onChangeNaoAtuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica={limparParticipacaoNoProgramaHabiteSeguro}
                onChangeProfissao={atualizarProfissao}
                onChangeGrauDeInstrucao={atualizarGrauDeInstrucao}
                onChangePais={atualizarPais}
                ehFormularioDeConjuge={true}
                formularioPermiteEdicao={situacaoDoCpf.permiteCadastro && !ehFormularioSomenteLeitura}
                onChangeEtnia={obterGruposEtnicos}
                onChangeGrupoEtnico={onChangeGrupoEtnico}
                regimesDeCasamento={regimesDeCasamento}
                etnias={etnias}
                onChangeRegimeDeCasamento={onChangeRegimeDeCasamento}
                onChangeEstadoCivil={onChangeEstadoCivil}
                habilitarCampoCpf={habilitarCampoCpf}
                exibirCampoCidadeDeResidencia={false}
                ehAcessoAdministrativo={ehAcessoAdministrativo}
                exibirDialogoDeJuncaoDeInscricao={exibirDialogoDeJuncaoDeInscricao}
                cancelarJuncaoDaInscricao={cancelarJuncaoDaInscricao}
                confirmarJuncaoDaInscricao={confirmarJuncaoDaInscricao}
                informacaoParaJuncaoDeInscricao={informacaoParaJuncaoDeInscricao}
                realizandoJuncaoDeInscricao={realizandoJuncaoDeInscricao}
                juntarInscricao={juntarInscricao}
                exibirCamposDeEmancipacao={exibirCamposDeEmancipacao}
                onChangeEmancipacao={onChangeEmancipacao}
                tiposDeDeficiencia={tiposDeDeficiencias}
                onChangeCidadeDeNaturalidade={onChangeCidadeDeNaturalidade}
              />
            </Container>
          </div>
        </div>}
    </>
  );
};
export default DadosConjuge;