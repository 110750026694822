import IPerguntaDeMoradiaValidacaoCampos, { IPerguntaDeMoradiaValidacaoErroCampos } from '../../interfaces/IPerguntaDeMoradiaValidacao';

let _situacao: string | undefined;
let _valorDoAluguel: number | undefined;
let _materialDaConstrucao: string | undefined;
let _moraEmAreaDeRisco: boolean | undefined;
let _areasDeRisco: string[] | undefined;
let _onusExcessivo: boolean | undefined;
let _adensamentoExcessivo: boolean | undefined;
let _habitacaoPrecaria: boolean | undefined;

const situacaoObrigatorio = () => {
  if (_situacao === undefined || _situacao == '')
    return true;
};

const valorDoAluguelObrigatorio = () => {
  if (_situacao == 'Alugada' && _valorDoAluguel === 0)
    return true;
};

const materialDaConstrucaoObrigatorioSeSituacaoNaoForDeRua = () => {
  if ((_materialDaConstrucao == undefined || _materialDaConstrucao == '' || _materialDaConstrucao == '0') && _situacao !='EmSituacaoDeRua')
    return true;
};

const moraEmAreadeRiscoObrigatorio = () => {
  if (_moraEmAreaDeRisco == undefined)
    return true;
};

const areasDeRiscoObrigatorio = () => {
  const possuiAreasDeRisco = _areasDeRisco != undefined && _areasDeRisco.length > 0;

  if (_moraEmAreaDeRisco && !possuiAreasDeRisco)
    return true;
};

const adensamentoExcessivoObrigatorioSeSituacaoNaoForEmSituacaoDeRua = () => {
  if (_adensamentoExcessivo == undefined && _situacao !='EmSituacaoDeRua')
    return true;
};

const onusExcessioObrigatorio = () => {
  if (_situacao == 'Alugada' && _onusExcessivo == undefined)
    return true;
};

const habitacaoPrecariaObrigatorio = () => {
  if (_habitacaoPrecaria == undefined)
    return true;
};

const validacoes: IPerguntaDeMoradiaValidacaoCampos = {
  situacao: [situacaoObrigatorio],
  valorDoAluguel: [valorDoAluguelObrigatorio],
  materialDaConstrucao: [materialDaConstrucaoObrigatorioSeSituacaoNaoForDeRua],
  moraEmAreaDeRisco: [moraEmAreadeRiscoObrigatorio],
  areasDeRisco: [areasDeRiscoObrigatorio],
  onusExcessivo: [onusExcessioObrigatorio],
  adensamentoExcessivo: [adensamentoExcessivoObrigatorioSeSituacaoNaoForEmSituacaoDeRua],
  habitacaoPrecaria:[habitacaoPrecariaObrigatorio]
};

const aplicarFuncoesDeValidacao = (campo: string, situacao: string, valorDoAluguel: number | undefined, materialDaConstrucao: string,
  moraEmAreaDeRisco: boolean, areasDeRisco: string[], onusExcessivo?: boolean, adensamentoExcessivo?: boolean, habitacaoPrecaria?: boolean) => {

  //@ts-ignore
  const funcoes = validacoes[campo] || [];
  _situacao = situacao;
  _valorDoAluguel = valorDoAluguel;
  _materialDaConstrucao = materialDaConstrucao;
  _moraEmAreaDeRisco = moraEmAreaDeRisco;
  _areasDeRisco = areasDeRisco;
  _onusExcessivo = onusExcessivo;
  _adensamentoExcessivo = adensamentoExcessivo;
  _habitacaoPrecaria = habitacaoPrecaria;

  const retornosComErro = funcoes.map((f: Function) => f.call(campo)).filter((r: any) => !!r);

  return retornosComErro[0];
};

const validarFormulario = (situacao: string, valorDoAluguel: number | undefined, materialDaConstrucao: string, moraEmAreaDeRisco: boolean, areasDeRisco: string[],
  onusExcessivo?: boolean, adensamentoExcessivo?: boolean, habitacaoPrecaria?: boolean): IPerguntaDeMoradiaValidacaoErroCampos => {

  const errosValidacao: IPerguntaDeMoradiaValidacaoErroCampos = Object.keys(validacoes).reduce((erros, campo) => {
    //@ts-ignore
    const erro = aplicarFuncoesDeValidacao(campo, situacao, valorDoAluguel, materialDaConstrucao, moraEmAreaDeRisco, areasDeRisco, onusExcessivo, adensamentoExcessivo, habitacaoPrecaria);
    if (erro)
      return { ...erros, [campo]: erro };

    return erros;
  }, {});

  return errosValidacao;
};

export default { validarCampo: aplicarFuncoesDeValidacao, validarFormulario };