import React, { useState } from 'react';
import CampoDeTexto from '../CampoDeTexto';
import CampoCidade from '../CampoCidade';
import CampoDistrito from '../CampoDistrito';
import servicoEndereco from '../../componentes/AutocompleteEndereco/servicoEndereco';
import CampoCep from './CampoCep';
import '../../estilos/estilos.scss';
import { mapearDistritosDaCidadeParaSelect } from '../../selectors';
import Section from '../Section';
import { IndicadorPasso } from '../../componentes';
import IEndereco from '../../interfaces/IEndereco';
import IDistrito from '../../interfaces/IDistrito';
import ICidade from '../../interfaces/ICidade';
import { IFormularioDeEnderecoValidacaoErroCampos } from '../../interfaces/IFormularioDeEnderecoValidacao';
import { useSelector } from 'react-redux';
import initialState from '../../reducers/initialState.reducer';
import ICidadeParaSelect from '../../interfaces/ICidadeParaSelect';
import { GerenciadorDeMensagem } from '@digix-ui/componentes';

interface IFormularioDeEnderecoProps {
    dadosDoEndereco: IEndereco,
    titulo: string,
    bloquearMudancaDeCidade: boolean,
    moraEmDistrito: boolean,
    erros?: IFormularioDeEnderecoValidacaoErroCampos,
    ehCepPadrao: boolean,
    atualizarComDadosDoEndereco: (endereco: IEndereco) => void,
    atualizarMoraEmDistrito: (moraEmDistrito: boolean) => void,
    atualizarErros: (erros: IFormularioDeEnderecoValidacaoErroCampos) => void,
    atualizarCepPadrao: (ehCepPadrao: boolean) => void
    onBlur: (evento: React.ChangeEvent<HTMLInputElement>) => void,
    cidades: ICidadeParaSelect[],
    disabled: boolean
}

const FormularioDeEndereco = ({ dadosDoEndereco, titulo, bloquearMudancaDeCidade, moraEmDistrito, erros, ehCepPadrao,
  atualizarComDadosDoEndereco, atualizarMoraEmDistrito, atualizarErros, atualizarCepPadrao, onBlur, cidades, disabled }: IFormularioDeEnderecoProps) => {

  const todasAsCidades = useSelector((state: typeof initialState) => state.cidades) as ICidade[];
  const todosOsDistritos = useSelector((state: typeof initialState) => state.distritos) as IDistrito[];
  const distritosDaCidadeParaSelect = mapearDistritosDaCidadeParaSelect(dadosDoEndereco.cidade, todosOsDistritos);
  const exibirDistrito = !!distritosDaCidadeParaSelect.length;
  const [permiteAlterarEndereco, setPermiteAlterarEndereco] = useState<boolean>(ehCepPadrao || false);
  const removerMensagemDeValidacao = (campo: string) => {
    atualizarErros({ ...erros, [campo]: false });
  };

  const atualizarCidade = (idDaCidade?: number | null) => {
    const cidade = idDaCidade ? todasAsCidades.find(cidade => cidade.id == idDaCidade) : {} as ICidade;
    let endereco = {
      ...dadosDoEndereco,
      cep: cidade?.cepPadrao || '',
      cidade: cidade || {},
      logradouro: '',
      numero: '',
      bairro: '',
      complemento: '',
      distrito: {}
    } as IEndereco;
    atualizarComDadosDoEndereco(endereco);
    if (!!cidade) {
      atualizarCepPadrao(!!cidade.cepPadrao);
      setPermiteAlterarEndereco(!!cidade.cepPadrao);
    }
  };

  const limparDetalhesDoEndereco = (endereco: IEndereco) => {
    endereco.logradouro = '';
    endereco.numero = '';
    endereco.bairro = '';
    endereco.complemento = '';
  };

  const atualizarDistrito = (idDoDistrito: number) => {
    const distritoEscolhido = todosOsDistritos.find(distrito => distrito.id === idDoDistrito);
    if (distritoEscolhido) {
      removerMensagemDeValidacao('cep');
      let endereco = {
        ...dadosDoEndereco,
        cep: distritoEscolhido.cepPadrao,
        distrito: {
          id: idDoDistrito,
          cepPadrao: distritoEscolhido.cepPadrao,
          nome: distritoEscolhido.nome,
          cidade: distritoEscolhido.cidade
        } as IDistrito,
      } as IEndereco;

      limparDetalhesDoEndereco(endereco);
      atualizarComDadosDoEndereco(endereco);
      atualizarCepPadrao(true);
      setPermiteAlterarEndereco(true);
    }
  };

  const voltarParaCepPadraoDaCidade = () => {
    atualizarCidade(dadosDoEndereco.cidade.id);
  };

  const limparDistrito = () => {
    voltarParaCepPadraoDaCidade();
    removerMensagemDeValidacao('logradouro');
    removerMensagemDeValidacao('numero');
    removerMensagemDeValidacao('bairro');
    atualizarMoraEmDistrito(false);
  };

  const atualizarEnderecoPorCep = async (evento: React.ChangeEvent<HTMLInputElement>) => {
    const tamanhoValidoParaCep = 9;
    const cep = evento.target.value;
    const cepValido = cep.length === tamanhoValidoParaCep;
    if (cepValido) {
      const enderecoObtido = await servicoEndereco.obterEnderecoPeloViaCep(cep);

      if(!!enderecoObtido.CidadeId && enderecoObtido.CidadeId != dadosDoEndereco.cidade.id)
        GerenciadorDeMensagem.criarMensagem({
          tipo: 'erro',
          icone: 'far fa-times-circle',
          titulo: 'Atenção!',
          texto: 'CEP informado precisa ser da mesma cidade da inscrição',
        });

      if (enderecoObtido && enderecoObtido.CidadeId && enderecoObtido.CidadeId == dadosDoEndereco.cidade.id) {
        atualizarComDadosDoEndereco({
          ...dadosDoEndereco,
          cep: cep,
          bairro: enderecoObtido.Bairro,
          logradouro: `${enderecoObtido.Logradouro}`,
          numero: '',
          complemento: ''
        });
        limparMensagensDeValidacaoNoEndereco();
        setPermiteAlterarEndereco(!(enderecoObtido.Logradouro && enderecoObtido.Bairro));
        return;
      }
      setPermiteAlterarEndereco(true);
    }

    dadosDoEndereco.cep = cep;
    limparDetalhesDoEndereco(dadosDoEndereco);
    atualizarComDadosDoEndereco(dadosDoEndereco);
  };

  const limparMensagensDeValidacaoNoEndereco = () => {
    removerMensagemDeValidacao('logradouro');
    removerMensagemDeValidacao('bairro');
    removerMensagemDeValidacao('numero');
  };

  const removerMensagemDoCampoObrigatorio = (evento: React.ChangeEvent<HTMLInputElement>) =>
    removerMensagemDeValidacao(evento.target.name);

  const atualizarEndereco = (evento: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evento.target;
    atualizarComDadosDoEndereco({ ...dadosDoEndereco, [name]: value });
  };

  const inputEvents = {
    onBlur: onBlur,
    onFocus: removerMensagemDoCampoObrigatorio,
    onChange: atualizarEndereco
  };

  return (
    <form className="formulario formulario_medio">
      <Section comMargemSuperiorMedia={false}>
        <IndicadorPasso
          iconeDoPasso='fa-map-marker-alt'
          titulo='Endereço'
          descricao='Preencha todos os campos abaixo. Todos os dados deverão ser comprovados posteriormente.' />
        <hr />
        <div className="grade__coluna u-largura-total">
          <h2 className="titulo titulo_medio texto_cor-secundaria"> <strong>{titulo}</strong></h2>
        </div>
        <div className="grade__linha grade__linha_alinhar-vertical-a-base">
          <CampoCidade
            label="Cidade"
            placeholder="Selecione sua cidade"
            onChange={atualizarCidade}
            cidade={dadosDoEndereco?.cidade?.id || ''}
            disabled={bloquearMudancaDeCidade || disabled}
            erro={erros?.cidade}
            cidades={cidades}
          />
          <CampoDeTexto
            colunas="2"
            label="UF"
            name="uf"
            value={dadosDoEndereco?.estado?.uf}
            disabled />
        </div>
        {exibirDistrito && (
          <CampoDistrito
            label="Mora em Distrito?"
            moraEmDistrito={moraEmDistrito}
            distrito={!!dadosDoEndereco?.distrito ? dadosDoEndereco?.distrito.id : 0}
            distritos={distritosDaCidadeParaSelect}
            atualizarDistrito={atualizarDistrito}
            limparDistrito={limparDistrito}
            erroMoraEmDistrito={erros?.moraEmDistrito}
            erroDistrito={erros?.distrito}
            atualizarMoraEmDistrito={atualizarMoraEmDistrito}
            disabled={disabled}
          />)}
        <div className="grade__linha grade__linha_alinhar-vertical-a-base">
          <CampoCep
            valor={dadosDoEndereco?.cep}
            erro={erros?.cep ? 'Campo obrigatório' : ''}
            onChange={atualizarEnderecoPorCep}
            onBlur={inputEvents.onBlur}
            onFocus={inputEvents.onFocus}
            desabilitado={ehCepPadrao || disabled} />
          <CampoDeTexto
            label="Endereço"
            name="logradouro"
            value={dadosDoEndereco?.logradouro}
            erro={erros?.logradouro ? 'Campo obrigatório' : ''}
            {...inputEvents}
            disabled={!permiteAlterarEndereco || disabled}
            maxLength={100} />
        </div>
        <div className="grade__linha grade__linha_alinhar-vertical-a-base">
          <CampoDeTexto
            label="Número"
            name="numero"
            value={dadosDoEndereco?.numero}
            erro={erros?.numero ? 'Campo obrigatório' : ''}
            maxLength={10}
            {...inputEvents}
            disabled={disabled} />
          <CampoDeTexto
            label="Complemento"
            name="complemento"
            value={dadosDoEndereco?.complemento}
            maxLength={100}
            required={false}
            {...inputEvents}
            disabled={disabled} />
        </div>
        <CampoDeTexto
          label="Bairro"
          name="bairro"
          value={dadosDoEndereco?.bairro}
          erro={erros?.bairro ? 'Campo obrigatório' : ''}
          {...inputEvents}
          disabled={!permiteAlterarEndereco || disabled}
          maxLength={100} />
      </Section>
    </form>
  );
};

export default FormularioDeEndereco;