import { Component } from 'react';
import { connect } from 'react-redux';
import { BotTrap, CampoDeTexto, CampoCidade, LayoutDaPaginaDeEntrada } from '../../../componentes';
import { mapearCidadesParaSelect } from '../../../selectors';
import { iniciarCadastroDeNovaInscricao } from '../../../actions/inscricao.actions';
import validacao from './validacao';
import { BotaoComTexto, GerenciadorDeMensagem, LinkComIcone} from '@digix-ui/componentes';
import PropTypes from 'prop-types';
import { detectarCapsLock, detectarLetrasMaiusculas } from '../../../helpers/deteccaoDeEventos';
import CampoDeTextoComMascara from '../../../componentes/CampoDeTextoComMascara';

class CadastroDeInscricao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      erros: {},
      dadosDoCadastro: {
        cidade: '',
        cpf: '',
        senha: '',
        confirmacaoDeSenha: ''
      },
      capsLockEstaAtivo: false
    };
  }

  componentDidMount() {
    document.querySelector('#foco-inicial-do-teclado').focus();
  }

  onBotTrapRef = input => {
    this.botTrapInput = input;
  };

  onChange = evento => {
    const { name, value } = evento.target;

    this.setState({
      dadosDoCadastro: {
        ...this.state.dadosDoCadastro,
        [name]: value
      }
    });

    this.removerErro(name);
  };

  onBlur = evento => {
    const { name, value } = evento.target;
    const { erros, dadosDoCadastro } = this.state;
    const erro = validacao.validarCampo(dadosDoCadastro, name, value);

    if (erro)
      this.setState({
        erros: { ...erros, [name]: erro }
      });
  };

  onFocus = evento => {
    const { name } = evento.target;
    this.removerErro(name);
  };

  atualizarCidade = cidade => {
    this.setState({
      dadosDoCadastro: {
        ...this.state.dadosDoCadastro,
        cidade
      }
    });

    this.removerErro('cidade');
  };

  removerErro = campo => {
    const erros = { ...this.state.erros };
    delete erros[campo];
    this.setState({ erros });
  };

  iniciarCadastroDeNovaInscricao = async (event) => {
    event.preventDefault();

    if (this.botTrapInput.value)
      document.location.replace('https://www.google.com.br/');

    if (!this.validarFormulario()) {
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: 'Formulário inválido. Por favor informe os campos obrigatórios.'
      });
      return;
    }

    try {
      await this.props.iniciarCadastroDeNovaInscricao(this.state.dadosDoCadastro);
    } catch (e) {
      if (!e.response || !e.response.data) {
        const mensagemErro = 'Não foi possível prosseguir com sua incrição. Tente novamente. Caso o problema persista, entre em contato com o suporte.';

        this.setState({ mensagemErro });
        return;
      }

      const mensagemDeErro = typeof (e.response.data) === 'string'
        ? e.response.data
        : e.response.data?.mensagens?.length > 0 ? e.response.data.mensagens[0] : '';

      if (mensagemDeErro)
        GerenciadorDeMensagem.criarMensagem({
          tipo: 'erro',
          icone: 'far fa-times-circle',
          titulo: 'Identificamos um erro:',
          texto: mensagemDeErro
        });
    }
  };

  validarFormulario = () => {
    const dadosDoCadastro = this.state.dadosDoCadastro;
    const erros = validacao.validarFormulario(dadosDoCadastro);

    this.setState({ erros });

    return !Object.keys(erros).length;
  };

  limparMensagemDeErro = () => {
    this.setState({
      mensagemErro: false
    });
  };

  notificarCapsLockAtivo = estaAtivo => {
    this.setState({
      capsLockEstaAtivo: estaAtivo
    });
  };

  get inputEvents() {
    return {
      onChange: this.onChange,
      onBlur: this.onBlur,
      onFocus: this.onFocus
    };
  }

  render() {
    const {
      dadosDoCadastro: {
        cpf,
        senha,
        confirmacaoDeSenha
      },
      erros,
      mensagemErro,
      capsLockEstaAtivo
    } = this.state;

    const { cidades, history } = this.props;
    return (
      <LayoutDaPaginaDeEntrada
        mensagemDeErro={mensagemErro}
        limparMensagemDeErro={this.limparMensagemDeErro}>
        <div className="grade">
          <div className="grade__coluna">
            <h2 className="titulo titulo_grande texto_cor-secundaria u-margem-inferior-pequena">Faça sua inscrição aqui!</h2>
            <p id="foco-inicial-do-teclado" tabIndex="-1" className="texto">Para iniciar seu cadastro, informe os dados abaixo:</p>
          </div>
          <form onSubmit={this.iniciarCadastroDeNovaInscricao}
            className="formulario formulario_medio u-margem-inferior-media">
            <CampoCidade
              label="Cidade"
              name="cidade"
              placeholder="Selecione sua cidade"
              erro={erros.cidade}
              onChange={this.atualizarCidade}
              cidades={cidades}
            />
            <BotTrap onRef={this.onBotTrapRef} />
            <CampoDeTextoComMascara
              label="Seu CPF"
              mascara="999.999.999-99"
              name="cpf"
              value={cpf}
              tipo="text"
              erro={erros.cpf}
              {...this.inputEvents} />
            <hr className="u-margem-esquerda-pequena u-margem-direita-pequena" />
            <CampoDeTexto
              label="Senha de acesso"
              name="senha"
              value={senha}
              tipo="password"
              capsLockAtivo={capsLockEstaAtivo}
              onKeyPress={(e) => detectarLetrasMaiusculas(e, this.notificarCapsLockAtivo)}
              onKeyDown={(e) => detectarCapsLock(e, this.notificarCapsLockAtivo)}
              erro={erros.senha ? erros.senha : !!confirmacaoDeSenha && erros.confirmacaoDeSenha ? ' ' : null}
              {...this.inputEvents}>
              <p className="texto u-margem-inferior-pequena">Será solicitada sempre que for acessar sua inscrição.</p>
              <p className="texto">Deve ter no mínimo 6 caracteres, incluíndo letras e números.</p>
            </CampoDeTexto>
            <CampoDeTexto
              label="Confirme a senha de acesso"
              name="confirmacaoDeSenha"
              value={confirmacaoDeSenha}
              tipo="password"
              erro={erros.confirmacaoDeSenha}
              capsLockAtivo={capsLockEstaAtivo}
              onKeyPress={(e) => detectarLetrasMaiusculas(e, this.notificarCapsLockAtivo)}
              onKeyDown={(e) => detectarCapsLock(e, this.notificarCapsLockAtivo)}
              {...this.inputEvents} />
            <div className="grade__linha">
              <div className="grade__coluna">
                <BotaoComTexto
                  tamanho="largura-total"
                  cor="terciaria">
                  Avançar
                </BotaoComTexto>
              </div>
            </div>
          </form>
          <div className="pagina-de-entrada__links-auxiliares">
            <LinkComIcone
              href="/login"
              onClick={() => history.push('login')}
              cor="info"
              icone="far fa-long-arrow-left"
              posicaoDoIcone="esquerda"
              className="u-texto-negrito"> Já tem uma inscrição? Acessar</LinkComIcone>
          </div>
        </div>
      </LayoutDaPaginaDeEntrada>
    );
  }
}

const mapStateToProps = ({ cidades }) => ({
  cidades: mapearCidadesParaSelect(cidades)
});

const mapDispatchToProps = dispatch => ({
  iniciarCadastroDeNovaInscricao: dadosDoCadastro => dispatch(iniciarCadastroDeNovaInscricao(dadosDoCadastro))
});

export default connect(mapStateToProps, mapDispatchToProps)(CadastroDeInscricao);

CadastroDeInscricao.propTypes = {
  cidades: PropTypes.array,
  iniciarCadastroDeNovaInscricao: PropTypes.func
};