
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { IndicadorPasso, CampoRadioButton, Header, MensagemDeErro } from '../../componentes';
import Container from '../../componentes/Container';
import ContainerCabecalho from '../../componentes/ContainerCabecalho';
import ContainerConteudo from '../../componentes/ContainerConteudo';
import ContainerRodape from '../../componentes/ContainerRodape';
import IInformacoesDaFamilia from '../../interfaces/IInformacoesDaFamilia';
import initialState from '../../reducers/initialState.reducer';
import { useDispatch } from 'react-redux';
import { GerenciadorDeMensagem, Cartao, Mensagem } from '@digix-ui/componentes';
import '../../estilos/estilos.scss';
import { salvarInformacoesDaFamilia } from '../../servicos/informacoesDaFamilia';
import { salvarInformacoesDaFamiliaSucesso } from '../../actions/informacoesDaFamilia.actions';
import { IInformacoesDaFamiliaErroCampos } from '../../interfaces/IInformacaoDaFamiliaValidacao';
import BarraDeNavegacao from '../../componentes/BarraDeNavegacao';
import validacao from './validacoes';
import IQuestaoDoFormulario from '../../interfaces/IQuestaoDoFormulario';
import IMoradia from '../../interfaces/IMoradia';
import './styles.scss';
import IDemandaEspecifica from '../../interfaces/IDemandaEspecifica';
import IDemandaEspecificaDaInscricao from '../../interfaces/IDemandaEspecificaDaInscricao';
import ICidade from '../../interfaces/ICidade';

const InformacoesDaFamilia = () => {

  const inscricao = useSelector((state: typeof initialState) => state.inscricao);
  const HashDaQuestaoDoFormularioDoProjetoEmendasCidades = '3ffb5986-6f04-4b43-8bca-4476e638959e';
  const moradia = useSelector((state: typeof initialState) => state.moradia) as IMoradia;
  const questoesDoFormulario = useSelector((state: typeof initialState) => state.questoesDoFormulario) as IQuestaoDoFormulario[];
  const [informacoesDaFamilia, setInformacoesDaFamilia] = useState<IInformacoesDaFamilia>(useSelector((state: typeof initialState) => state.informacoesDaFamilia as IInformacoesDaFamilia));
  const [camposObrigatorios, setCamposObrigatorios] = useState<IInformacoesDaFamiliaErroCampos>();
  let tentandoSalvar = false;
  const ehAcessoAdministrativo: boolean = useSelector((state: typeof initialState) => state.ehAcessoAdministrativo);
  const idDaInscricao = useSelector((state: typeof initialState) => state.inscricao.id) as Number;
  const dispatch = useDispatch();
  const [jaAssistiuOVideo, setJaAssistiuOVideo] = useState<boolean>(informacoesDaFamilia?.temInteresseNoEmendasCidades != undefined);
  const idCidade = +moradia?.endereco?.cidade?.id;
  const exibirPerguntaDoEmendasCidades = questoesDoFormulario.some(b => b.hash == HashDaQuestaoDoFormularioDoProjetoEmendasCidades && b.cidades.includes(idCidade));
  const habilitarPerguntaDoEmendasCidades = jaAssistiuOVideo || ehAcessoAdministrativo;
  const ehNovaInscricao = useSelector((state: typeof initialState) => state.ehNovaInscricao) as boolean;
  const ehFormularioSomenteLeitura = useSelector((state: typeof initialState) => state.ehFormularioSomenteLeitura) as boolean;
  const demandasEspecificas = useSelector((state: typeof initialState) => state.demandasEspecificas) as IDemandaEspecifica[];
  const demandasEspecificasDaCidade = demandasEspecificas.filter((x: IDemandaEspecifica) => x.cidade.id == idCidade);

  const inscricaoEstaVazioNaMemoria = Object.keys(inscricao).length == 1;

  useEffect(() => {
    if (inscricaoEstaVazioNaMemoria && !ehNovaInscricao)
      dispatch(push('/menu-inscricao'));

    if (!ehAcessoAdministrativo)
      adicionandoLogosEmendasDaCidades();

  }, []);

  const adicionandoLogosEmendasDaCidades = () => {

    const cabecalho = document.querySelector('.logo-emendas .cartao__cabecalho');
    if (cabecalho) {
      const imgMinhaCasaMinhaVida = document.createElement('img');
      imgMinhaCasaMinhaVida.src = require('../../imagens/minha_casa_minha_vida.png');
      imgMinhaCasaMinhaVida.alt = 'Minha Casa Minha Vida';
      imgMinhaCasaMinhaVida.className = 'img-minha-casa-minha-vida';

      const igmFgts = document.createElement('img');
      igmFgts.src = require('../../imagens/fgts.png');
      igmFgts.alt = 'FGTS';
      igmFgts.className = 'img-fgts';

      cabecalho.appendChild(imgMinhaCasaMinhaVida);
      cabecalho.appendChild(igmFgts);
    }
  };

  function mapearValor(valor: string) {
    return valor.toLowerCase() === 'true' ? true : (valor.toLowerCase() === 'false' ? false : valor);
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setInformacoesDaFamilia({ ...informacoesDaFamilia, [name]: mapearValor(value) });
    removerErro(name);
  }

  function removerErro(campo: string) {
    setCamposObrigatorios({ ...camposObrigatorios, [campo]: '' });
  }

  function desabilitarInteresseEmFinanciamento(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setInformacoesDaFamilia({ ...informacoesDaFamilia, [name]: mapearValor(value), autorizaConsultaNosOrgaosDeProtecaoAoCredito: undefined });
    removerErro(name);
  }

  const validarFormulario = () => {
    const errosValidacao = validacao.validarFormulario(informacoesDaFamilia, exibirPerguntaDoEmendasCidades, demandasEspecificasDaCidade);
    setCamposObrigatorios(errosValidacao);
    return Object.keys(errosValidacao).length > 0;
  };

  const assistiuOVideo = () => {
    setJaAssistiuOVideo(true);
  };

  function posicionarTela() {
    if (tentandoSalvar) {
      let campoComErro: HTMLElement | null = document.querySelector('.formulario__mensagem_erro');

      if (campoComErro) {
        const alturaDoCabecalhoDaPagina = 115;
        campoComErro.scrollIntoView({ behavior: 'smooth' });
        window.scrollBy(0, -alturaDoCabecalhoDaPagina);
      }
      tentandoSalvar = false;
    }
  }

  async function clickSalvar() {
    tentandoSalvar = true;

    if (validarFormulario()) {
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: 'Formulário inválido. Por favor informe os campos obrigatórios.'
      });
      posicionarTela();
      return;
    }

    await salvarInformacoesDaFamilia(informacoesDaFamilia, idDaInscricao);
    dispatch(salvarInformacoesDaFamiliaSucesso(informacoesDaFamilia));

    GerenciadorDeMensagem.criarMensagem({
      tipo: 'sucesso',
      icone: 'far fa-check-circle',
      titulo: 'Tudo certo!',
      texto: 'Informações dos programas habitacionais cadastradas com sucesso.'
    });

    dispatch(push('/menu-inscricao'));
  }

  const onChangeDemandaEspecifica = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const id = name.split('_')[1];

    let demandasEspecificasDaInscricao = informacoesDaFamilia?.demandasEspecificasDaInscricao as IDemandaEspecificaDaInscricao[] || [];
    let demandaEspecifica = demandasEspecificasDaInscricao.find(x => x.demandaEspecifica.id == parseInt(id));

    if (!!demandaEspecifica)
      demandasEspecificasDaInscricao = demandasEspecificasDaInscricao?.map((demanda: IDemandaEspecificaDaInscricao) =>
        demanda.demandaEspecifica.id == parseInt(id) ? { ...demanda, temInteresse: value.toLowerCase() === 'true' } : demanda);
    else
      demandasEspecificasDaInscricao.push({
        temInteresse: value.toLowerCase() === 'true',
        demandaEspecifica: { id: parseInt(id) } as IDemandaEspecifica,
        cidade: { id: idCidade } as ICidade
      } as IDemandaEspecificaDaInscricao);

    setInformacoesDaFamilia({ ...informacoesDaFamilia, demandasEspecificasDaInscricao: demandasEspecificasDaInscricao });
    removerErro(name);
  };

  const obterDemandaEspecificaDaInscricaoPorId = (idDemanda: number) => {
    return informacoesDaFamilia.demandasEspecificasDaInscricao?.find(x => x.demandaEspecifica.id == idDemanda);
  };

  return (
    <>
      {(!inscricaoEstaVazioNaMemoria || ehNovaInscricao) &&
        <div className="pagina-informacoes-da-familia">
          {!ehAcessoAdministrativo && (
            //@ts-ignore
            <Header subTitulo='Cadastro - Programas Habitacionais' comBotaoVoltar={true} urlBotaoVoltar={'/menu-inscricao'} />
          )}
          {ehAcessoAdministrativo && (
            <BarraDeNavegacao
              titulo="Cadastro - Programas Habitacionais"
              comBotaoVoltar={true}
              acaoBotaoVoltar={() => dispatch(push('/menu-inscricao'))}
              comBotaoADireita={true}
              textoBotaoADireita="Menu de inscrição"
              iconeBotaoADireita={false}
              acaoBotaoADireita={() => dispatch(push('/menu-inscricao'))}
            />
          )}

          <div className="pagina-informacoes-da-familia__container">
            <Container tamanhoDoContainer={''}>
              <ContainerCabecalho className=''>
                <IndicadorPasso
                  iconeDoPasso='fa-layer-group'
                  titulo='Programas habitacionais'
                  descricao='Preencha todos os campos abaixo conforme seu interesse.' />
              </ContainerCabecalho>
              <ContainerConteudo className=''>
                <fieldset className="formulario__grupo-de-inputs" data-test-id="tipo-imovel-interesse">
                  <legend className="formulario__label">
                    Possui interesse por casa térrea ou apartamento?
                  </legend>
                  {camposObrigatorios?.tipoDeImovelDeInteresse &&
                    <MensagemDeErro />}
                  <CampoRadioButton
                    name="tipoDeImovelDeInteresse"
                    value="Casa"
                    label="Casa térrea"
                    checked={informacoesDaFamilia.tipoDeImovelDeInteresse}
                    onChange={onChange} mesmaLinha={false}
                    disabled={ehFormularioSomenteLeitura} />
                  <CampoRadioButton
                    name="tipoDeImovelDeInteresse"
                    value="Apartamento"
                    label="Apartamento"
                    checked={informacoesDaFamilia.tipoDeImovelDeInteresse}
                    onChange={onChange} mesmaLinha={false}
                    disabled={ehFormularioSomenteLeitura} />
                  <CampoRadioButton
                    name="tipoDeImovelDeInteresse"
                    value="CasaOuApartamento"
                    label="Pode ser um ou o outro"
                    checked={informacoesDaFamilia.tipoDeImovelDeInteresse}
                    onChange={onChange} mesmaLinha={false}
                    disabled={ehFormularioSomenteLeitura} />
                </fieldset>
                <hr />

                {exibirPerguntaDoEmendasCidades &&
                  <Cartao
                    titulo={<span className="titulo_medio">Projeto Emendas Cidades</span>}
                    cor="secundaria"
                    icone="fal fa-layer-plus"
                    className="u-margem-superior-media logo-emendas">
                    <p className="texto_pequeno">
                      O projeto Emendas Cidades, que é uma parceria entre a CAIXA e a AGEHAB, está oferecendo ajuda para as pessoas financiarem moradia. Ele oferece subsídio para ajudar a financiar imóveis junto as construtoras cadastradas.
                    </p>
                    {!ehAcessoAdministrativo && <>
                      <br />
                      <p className="texto_pequeno">
                        Para se mostrar interessado no Projeto Emendas Cidades, é preciso assistir o vídeo completo abaixo.
                      </p>
                      <Mensagem
                        tipo='atencao'
                        titulo="Fique atento"
                        icone='far fa-exclamation-triangle'>
                        É necessário ver o vídeo completo para demonstrar interesse no Projeto Emendas Cidades.
                      </Mensagem>
                      <div className="u-margem-superior-media video-container">
                        <video className='container_bordas_redondas' playsInline controls onEnded={assistiuOVideo} >
                          <source src={require('../../assets/Emendas_da_cidade.mp4')} type="video/mp4" />
                        </video>
                      </div>
                    </>
                    }
                    <fieldset className="formulario__grupo-de-inputs u-margem-superior-media" data-test-id="possui-interesse-no-emendas-cidades">
                      <legend className="formulario__label">Possui interesse em participar do Projeto Emendas Cidades?</legend>
                      {camposObrigatorios?.temInteresseNoEmendasCidades && <MensagemDeErro />}
                      <CampoRadioButton
                        mesmaLinha
                        name="temInteresseNoEmendasCidades"
                        value="true"
                        label="Sim"
                        checked={informacoesDaFamilia.temInteresseNoEmendasCidades != null ? informacoesDaFamilia.temInteresseNoEmendasCidades.toString() : ''}
                        onChange={onChange}
                        disabled={!habilitarPerguntaDoEmendasCidades || ehFormularioSomenteLeitura} />
                      <CampoRadioButton
                        mesmaLinha
                        name="temInteresseNoEmendasCidades"
                        value="false"
                        label="Não"
                        checked={informacoesDaFamilia.temInteresseNoEmendasCidades != null ? informacoesDaFamilia.temInteresseNoEmendasCidades.toString() : ''}
                        onChange={onChange}
                        disabled={!habilitarPerguntaDoEmendasCidades || ehFormularioSomenteLeitura} />
                    </fieldset>
                  </Cartao>
                }
                <Cartao
                  titulo={<span className="titulo_medio">Projeto Lote Urbanizado</span>}
                  subtitulo={<strong>Lei Estadual nº 4.888 e 5.016 - Decreto Estadual nº 14.783.</strong>}
                  cor="secundaria"
                  icone="fal fa-layer-plus"
                  className="u-margem-superior-media">
                  <p className="texto_pequeno">
                    O projeto tem parceria com a Prefeitura Municipal, que doa o terreno, AGEHAB subsidia a construção da base de uma residência (constituída de fundação, instalações hidráulicas e sanitárias enterradas, contrapiso e a 1ª fiada de alvenaria) e o Proponente constrói a casa, no prazo de 24 meses.
                  </p>
                  <fieldset className="formulario__grupo-de-inputs u-margem-superior-media" data-test-id="possui-interesse-lote-urbanizado">
                    <legend className="formulario__label">Possui interesse em participar da seleção?</legend>
                    {camposObrigatorios?.temInteresseNoLoteUrbanizado && <MensagemDeErro />}
                    <CampoRadioButton
                      mesmaLinha
                      name="temInteresseNoLoteUrbanizado"
                      value="true"
                      label="Sim"
                      checked={informacoesDaFamilia.temInteresseNoLoteUrbanizado != null ? informacoesDaFamilia.temInteresseNoLoteUrbanizado.toString() : ''}
                      onChange={onChange}
                      disabled={ehFormularioSomenteLeitura} />
                    <CampoRadioButton
                      mesmaLinha
                      name="temInteresseNoLoteUrbanizado"
                      value="false"
                      label="Não"
                      checked={informacoesDaFamilia.temInteresseNoLoteUrbanizado != null ? informacoesDaFamilia.temInteresseNoLoteUrbanizado.toString() : ''}
                      onChange={onChange}
                      disabled={ehFormularioSomenteLeitura} />
                  </fieldset>
                </Cartao>
                <Cartao
                  titulo={<span className="titulo_medio">Programa Financiado e Subsidiado com recurso do FGTS</span>}
                  subtitulo={<strong>Decreto Estadual nº 14.251 de 28/08/2015.</strong>}
                  cor="secundaria"
                  icone="fal fa-layer-plus"
                  className="u-margem-superior-media">
                  <p className="texto_pequeno">Visa financiar moradia com recurso do FGTS, para famílias que tenham renda familiar de R$ 1.500,00 a 7.050,00.</p>
                  <p className="texto_pequeno u-margem-superior-pequena">O Agente Financeiro é responsável pela análise e aprovação da capacidade financeira do proponente. Os valores das prestações a serem pagas, não poderão ultrapassar 30% da renda dos pretendentes proponentes.</p>
                  <p className="texto_pequeno u-margem-superior-pequena">O Programa apresenta subsídios Federal e Estadual, conforme a renda familiar.</p>
                  <fieldset className="formulario__grupo-de-inputs u-margem-superior-media" data-test-id="possui-interesse-fgts">
                    <legend className="formulario__label">Possui interesse em participar da seleção?</legend>
                    {camposObrigatorios?.temInteresseEmFinanciamento && <MensagemDeErro />}
                    <CampoRadioButton
                      name="temInteresseEmFinanciamento"
                      mesmaLinha
                      value="true"
                      label="Sim"
                      checked={informacoesDaFamilia.temInteresseEmFinanciamento != null ? informacoesDaFamilia.temInteresseEmFinanciamento.toString() : ''}
                      onChange={onChange}
                      disabled={ehFormularioSomenteLeitura} />
                    <CampoRadioButton
                      name="temInteresseEmFinanciamento"
                      mesmaLinha
                      value="false"
                      label="Não"
                      checked={informacoesDaFamilia.temInteresseEmFinanciamento != null ? informacoesDaFamilia.temInteresseEmFinanciamento.toString() : ''}
                      onChange={desabilitarInteresseEmFinanciamento}
                      disabled={ehFormularioSomenteLeitura} />
                  </fieldset>
                  {informacoesDaFamilia.temInteresseEmFinanciamento && <hr />}

                  {informacoesDaFamilia.temInteresseEmFinanciamento &&
                    <Cartao
                      titulo={<span className="titulo_medio">Autorização para consulta aos órgãos de proteção ao crédito</span>}
                      subtitulo={<strong>Artigo 2º do Decreto 14.251.</strong>}
                      cor="secundaria"
                      icone="fal fa-search"
                      className="cartao_sem-estilo u-margem-superior-media">
                      <p className="texto_pequeno">Autorizo a consulta dos meus dados cadastrais e do meu cônjuge junto ao SERASA, SPC e demais órgãos de proteção ao crédito, não se podendo atribuir a AGEHAB/MS qualquer responsabilidade, em sede administrativa ou judicial, pelo exercício dessas faculdades.</p>
                      <p className="texto_pequeno u-margem-superior-pequena">Fico ciente da utilização desta autorização durante a fase de inscrição e até a efetiva liquidação do financiamento.</p>
                      <fieldset className="formulario__grupo-de-inputs u-margem-superior-media" data-test-id="autoriza-consulta">
                        <legend className="formulario__label">Autoriza a consulta aos órgãos de proteção ao crédito?</legend>
                        {camposObrigatorios?.autorizaConsultaNosOrgaosDeProtecaoAoCredito && <MensagemDeErro />}
                        <CampoRadioButton
                          mesmaLinha
                          name="autorizaConsultaNosOrgaosDeProtecaoAoCredito"
                          value="true"
                          label="Sim, autorizo"
                          checked={informacoesDaFamilia.autorizaConsultaNosOrgaosDeProtecaoAoCredito != null ? informacoesDaFamilia.autorizaConsultaNosOrgaosDeProtecaoAoCredito.toString() : ''}
                          onChange={onChange}
                          disabled={ehFormularioSomenteLeitura} />
                        <CampoRadioButton
                          mesmaLinha
                          name="autorizaConsultaNosOrgaosDeProtecaoAoCredito"
                          value="false"
                          label="Não"
                          checked={informacoesDaFamilia.autorizaConsultaNosOrgaosDeProtecaoAoCredito != null ? informacoesDaFamilia.autorizaConsultaNosOrgaosDeProtecaoAoCredito.toString() : ''}
                          onChange={onChange}
                          disabled={ehFormularioSomenteLeitura} />
                      </fieldset>
                    </Cartao>
                  }
                </Cartao>
                <Cartao
                  titulo={<span className="titulo_medio">Projeto Bônus Moradia</span>}
                  subtitulo={<strong>Lei Estadual n° 4.888 Portaria normativa AGEHAB/MS Nº 208, de 12 de junho de 2024</strong>}
                  cor="secundaria"
                  icone="fal fa-layer-plus"
                  className="u-margem-superior-media">
                  <p className="texto_pequeno">O Projeto <strong>Bônus Moradia</strong> oferece subsídio para o financiamento de imóveis junto as construtoras cadastradas</p>
                  <Mensagem
                    tipo='atencao'
                    titulo="Fique atento"
                    icone='far fa-exclamation-triangle'>
                    {ehAcessoAdministrativo && <>
                      A renda familiar deverá ser entre R$ 1.500,00 e R$ 7.050,00. Caso a renda não esteja dentro dessa faixa, o pretendente não poderá participar e ser beneficiado pelo projeto.
                    </>}
                    {!ehAcessoAdministrativo && <>
                      Sua renda familiar deverá ser entre R$ 1.500,00 e R$ 7.050,00. Caso a renda não esteja dentro dessa faixa, você não poderá participar e ser beneficiado pelo projeto.
                    </>}
                  </Mensagem>
                  <fieldset className="formulario__grupo-de-inputs u-margem-superior-media" data-test-id="possui-interesse-bonus-moradia">
                    <legend className="formulario__label">Possui interesse em participar do Bônus Moradia?</legend>
                    {camposObrigatorios?.temInteresseNoBonusMoradia && <MensagemDeErro />}
                    <CampoRadioButton
                      name="temInteresseNoBonusMoradia"
                      mesmaLinha
                      value="true"
                      label="Sim"
                      checked={informacoesDaFamilia.temInteresseNoBonusMoradia != null ? informacoesDaFamilia.temInteresseNoBonusMoradia.toString() : ''}
                      onChange={onChange}
                      disabled={ehFormularioSomenteLeitura} />
                    <CampoRadioButton
                      name="temInteresseNoBonusMoradia"
                      mesmaLinha
                      value="false"
                      label="Não"
                      checked={informacoesDaFamilia.temInteresseNoBonusMoradia != null ? informacoesDaFamilia.temInteresseNoBonusMoradia.toString() : ''}
                      onChange={onChange}
                      disabled={ehFormularioSomenteLeitura} />
                  </fieldset>
                </Cartao>
                {demandasEspecificasDaCidade.length > 0 &&
                  <Cartao
                    titulo={<span className="titulo_medio">Programas Habitacionais por Município</span>}
                    subtitulo={<strong></strong>}
                    cor="secundaria"
                    icone="fal fa-layer-plus"
                    className="u-margem-superior-media">
                    <p className="texto_pequeno">Os programas habitacionais por município são programas fechados ou específicas do municipio, destinadas a promover o acesso à moradia digna para a população local, através de políticas que incluem desde subsídios para construção até regularização fundiária.</p>
                    {demandasEspecificasDaCidade.map(demandaEspecifica =>
                      <fieldset className="formulario__grupo-de-inputs u-margem-superior-media" data-test-id="possui-interesse-bonus-moradia" key={demandaEspecifica.id}>
                        <legend className="formulario__label">{demandaEspecifica.descricao}</legend>
                        {camposObrigatorios?.demandasEspecificasDaInscricao && obterDemandaEspecificaDaInscricaoPorId(demandaEspecifica.id)?.temInteresse == undefined && <MensagemDeErro />}
                        <CampoRadioButton
                          name={`temDemandaEspecifica_${demandaEspecifica.id}`}
                          mesmaLinha
                          value="true"
                          label="Sim"
                          checked={obterDemandaEspecificaDaInscricaoPorId(demandaEspecifica.id)?.temInteresse.toString() || ''}
                          onChange={onChangeDemandaEspecifica}
                          disabled={ehFormularioSomenteLeitura} />
                        <CampoRadioButton
                          name={`temDemandaEspecifica_${demandaEspecifica.id}`}
                          mesmaLinha
                          value="false"
                          label="Não"
                          checked={obterDemandaEspecificaDaInscricaoPorId(demandaEspecifica.id)?.temInteresse.toString() || ''}
                          onChange={onChangeDemandaEspecifica}
                          disabled={ehFormularioSomenteLeitura} />
                      </fieldset>
                    )}
                  </Cartao>
                }
              </ContainerConteudo>
              <ContainerRodape>
                <div className="grade__coluna">
                  <button
                    disabled={ehFormularioSomenteLeitura}
                    className="botao botao_medio botao_cor-secundaria botao_largura-total"
                    onClick={clickSalvar}>
                    Salvar
                  </button>
                </div>
              </ContainerRodape>
            </Container>
          </div>
        </div>}
    </>
  );
};

export default InformacoesDaFamilia;