import React, { useEffect, useState } from 'react';
import {  useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Header, IndicadorPasso } from '../../componentes';
import Container from '../../componentes/Container';
import ContainerCabecalho from '../../componentes/ContainerCabecalho';
import ContainerConteudo from '../../componentes/ContainerConteudo';
import ContainerRodape from '../../componentes/ContainerRodape';
import mascaras from '../../helpers/mascaras';
import verificadorDeItensDoComprovante from '../../helpers/verificadorDeItensDoComprovante';
import '../../estilos/estilos.scss';
import initialState from '../../reducers/initialState.reducer';
import IPretendente from '../../interfaces/IPretendente';
import IInscricao from '../../interfaces/IInscricao';
import IDependente from '../../interfaces/IDependente';
import IMoradia from '../../interfaces/IMoradia';
import {obterDadosGeraisDaInscricao} from '../../servicos/MenuDeInscricao';
import IDadosGeraisDaInscricao from '../../interfaces/IDadosGeraisDaInscricao';

const  ResumoDaInscricao = () => {

  const inscricao = useSelector((state: typeof initialState) => state.inscricao) as IInscricao;
  const dependentes = useSelector((state: typeof initialState) => state.dependentes) as IDependente[];
  const dadosDoPretendente = useSelector((state: typeof initialState) => state.dadosTitular) as IPretendente;
  const dadosDoConjuge = useSelector((state: typeof initialState) => state.dadosConjuge) as IPretendente;
  const moradia =  useSelector((state: typeof initialState) => state.moradia) as IMoradia;
  const naoTemDadosDoConjuge = Object.keys(dadosDoConjuge).length === 0;
  const naoTemDadosDeDeficienciaParaPretendente = dadosDoPretendente.deficiencias?.length === 0;
  const [obteveDadosGeraisDaInscricao, setObteveDadosGeraisDaInscricao] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [dadosGeraisDaInscricao, setDadosGeraisDaInscricao] = useState<IDadosGeraisDaInscricao>();
  const ehNovaInscricao = useSelector((state: typeof initialState) => state.ehNovaInscricao) as boolean;
  
  const inscricaoEstaVazioNaMemoria = Object.keys(inscricao).length == 1;

  const inscricaoPossuiFilhoMenorDeIdade = verificadorDeItensDoComprovante.inscricaoPossuiFilhoMenorDeIdade(dependentes);
  const inscricaoPossuiIdoso = verificadorDeItensDoComprovante.inscricaoPossuiIdoso(naoTemDadosDoConjuge, dadosDoPretendente, dadosDoConjuge, dependentes);
  const inscricaoPossuiPessoaComDeficiencia = verificadorDeItensDoComprovante.inscricaoPossuiPessoaComDeficiencia(naoTemDadosDoConjuge, naoTemDadosDeDeficienciaParaPretendente, dadosDoPretendente, dadosDoConjuge, dependentes);
  const inscricaoPossuiPessoaComDoencaCronica = verificadorDeItensDoComprovante.inscricaoPossuiPessoaComDoencaCronica(naoTemDadosDoConjuge, dadosDoPretendente, dadosDoConjuge, dependentes);
  const inscricaoPossuiMulherChefeDeFamilia = verificadorDeItensDoComprovante.inscricaoPossuiMulherChefeDeFamilia(dadosDoPretendente, naoTemDadosDoConjuge, dadosDoConjuge);
  const inscricaoPossuiMulherVitimaDeViolenciaDomestica = verificadorDeItensDoComprovante.inscricaoPossuiMulherVitimaDeViolenciaDomestica(dadosDoPretendente, naoTemDadosDoConjuge, dadosDoConjuge);
  const resumoPossuiInformacoesDaFamilia = inscricaoPossuiFilhoMenorDeIdade || inscricaoPossuiIdoso || inscricaoPossuiPessoaComDeficiencia || 
                                        inscricaoPossuiPessoaComDoencaCronica || inscricaoPossuiMulherChefeDeFamilia || inscricaoPossuiMulherVitimaDeViolenciaDomestica;

  useEffect(()  =>  {
    if (inscricaoEstaVazioNaMemoria && !ehNovaInscricao)
      dispatch(push('/menu-inscricao'));
    
    else if (!obteveDadosGeraisDaInscricao) {
      obterDadosGeraisDaInscricao(inscricao.id).then(resposta => {
        setDadosGeraisDaInscricao(resposta.data);
        setObteveDadosGeraisDaInscricao(true);
      });
    }
  }, []);

  const abrirTelaDeComprovante = () => {
    dispatch(push('/comprovante'));
  };

  return (
    <>
      {(!inscricaoEstaVazioNaMemoria || ehNovaInscricao) &&
      <div className="pagina-informacoes-da-familia">
        <Header subTitulo="Resumo da inscrição" comBotaoVoltar />
        <div className="pagina-endereco__container">
          <Container tamanhoDoContainer={''}>
            <ContainerCabecalho>
              <IndicadorPasso
                iconeDoPasso='fa-print'
                titulo="Resumo da inscrição"
                descricao="Antes de finalizar a inscrição, confira todos os dados fornecidos." />
            </ContainerCabecalho>
            <ContainerConteudo>
              <div className="resumo__numero-inscricao">
                <h3>
                  <span className="numero-inscricao__titulo">Número da inscrição: </span>
                  <span className="numero-inscricao__numero">{dadosGeraisDaInscricao?.numero}</span>
                </h3>
                <p>Inscrição feita em: {dadosGeraisDaInscricao?.dadosGeraisDeInformacoesDeCriacao.data}</p>
                <p>Atualizada em: {dadosGeraisDaInscricao?.dadosGeraisDeInformacoesDeAtualizacao?.data} </p>
              </div>

              <div className="resumo__bloco">
                <div className="bloco-resumo__titulo">
                  <h2 className="titulo titulo_medio texto_cor-secundaria">Dados pessoais do(a) pretendente</h2>
                </div>
                <div className="bloco-resumo__dados bloco-resumo__dados-pessoais">
                  <p>{dadosDoPretendente.nome}</p>
                  <p>Nasceu em {dadosDoPretendente.dataDeNascimento?.toString()}</p>
                  <p>CPF: {mascaras.aplicarMascaraDeCpf(dadosDoPretendente.cpf)}</p>
                  {dadosDoPretendente.nis &&
                  <p>NIS: {mascaras.aplicarMascaraDeNis(dadosDoPretendente.nis)}</p>}
                </div>
                <div className="bloco-resumo__dados bloco-resumo__dados-de-contato">
                  {dadosDoPretendente.contato?.telefoneParaRecado &&
                  <p>Recado: {dadosDoPretendente.contato?.telefoneParaRecado}</p>}
                  {dadosDoPretendente.contato?.telefoneCelular &&
                  <p>Celular: {dadosDoPretendente.contato?.telefoneCelular}</p>}
                  {dadosDoPretendente.contato?.email != '' &&
                  <p>{dadosDoPretendente.contato?.email}</p>}
                </div>
                <div className="bloco-resumo__link">
                  <Link className="botao botao_largura-total botao_contorno botao_pequeno botao_cor-secundaria" to={{
                    pathname: '/dados-titular',
                    state: { acessoFeitoNoResumoDaInscricao: true }
                  }}> Alterar
                  </Link>
                </div>
              </div>
              {!naoTemDadosDoConjuge &&
              <div className="resumo__bloco">
                <div className="bloco-resumo__titulo">
                  <h2 className="titulo titulo_medio texto_cor-secundaria">Dados pessoais do(a) cônjuge</h2>
                </div>
                <div className="bloco-resumo__dados bloco-resumo__dados-pessoais">
                  <p>{dadosDoConjuge.nome}</p>
                  <p>Nasceu em {dadosDoConjuge.dataDeNascimento?.toString()}</p>
                  <p>CPF: {mascaras.aplicarMascaraDeCpf(dadosDoConjuge.cpf)}</p>
                  {dadosDoConjuge.nis &&
                  <p>NIS: {mascaras.aplicarMascaraDeNis(dadosDoConjuge.nis)}</p>}
                </div>
                <div className="bloco-resumo__dados bloco-resumo__dados-de-contato">
                  {dadosDoConjuge.contato?.telefoneCelular &&
                  <p>Celular: {dadosDoConjuge.contato?.telefoneCelular}</p>}
                  {dadosDoConjuge.contato?.telefoneParaRecado &&
                  <p>Recado: {dadosDoConjuge.contato?.telefoneParaRecado}</p>}
                  {dadosDoConjuge.contato?.email != '' &&
                  <p>{dadosDoConjuge.contato?.email}</p>}
                </div>
                <div className="bloco-resumo__link">
                  <Link className="botao botao_largura-total botao_contorno botao_pequeno botao_cor-secundaria" to={{
                    pathname: '/dados-conjuge',
                    state: { acessoFeitoNoResumoDaInscricao: true }
                  }}> Alterar
                  </Link>
                </div>
              </div>}
              <div className="resumo__bloco">
                <div className="bloco-resumo__titulo">
                  <h2 className="titulo titulo_medio texto_cor-secundaria">Endereço</h2>
                </div>
                <div className="bloco-resumo__dados bloco-resumo_largura-total">
                  {Object.keys(moradia).length > 0 && 
                  <>
                    <p>{moradia.endereco.logradouro}, {moradia.endereco.numero}</p>
                    <p>Bairro {moradia.endereco.bairro}</p>
                    {!!moradia.endereco.distrito?.id ?
                      <p>{moradia.endereco.distrito.nome}, MS</p> :
                      <p>{moradia.endereco.cidade.nome}, MS</p>}
                    {!!moradia.endereco.cep &&
                    <p>CEP: {mascaras.aplicarMascaraDeCep(moradia.endereco.cep)}</p>}
                  </>
                  }
                </div>
                <div className="bloco-resumo__link">
                  <Link className="botao botao_largura-total botao_contorno botao_pequeno botao_cor-secundaria" to={{
                    pathname: '/endereco',
                    state: { acessoFeitoNoResumoDaInscricao: true }
                  }}> Alterar
                  </Link>
                </div>
              </div>
              {resumoPossuiInformacoesDaFamilia &&
              <div className="resumo__bloco">
                <div className="bloco-resumo__titulo">
                  <h2 className="titulo titulo_medio texto_cor-secundaria">Sobre a família</h2>
                </div>
                <ul className="bloco-resumo__dados bloco-resumo_largura-total">
                  {inscricaoPossuiMulherChefeDeFamilia &&
                  <li>Possui mulher chefe de família</li>}
                  {inscricaoPossuiMulherVitimaDeViolenciaDomestica &&
                  <li>Possui vítima de violência doméstica</li>}
                  {inscricaoPossuiFilhoMenorDeIdade &&
                  <li>Possui filhos menores de dezoito anos</li>}
                  {inscricaoPossuiIdoso &&
                  <li>Possui idoso na família</li>}
                  {inscricaoPossuiPessoaComDeficiencia &&
                  <li>Possui pessoa com deficiência</li>}
                  {inscricaoPossuiPessoaComDoencaCronica &&
                  <li>Possui pessoa com doença crônica incapacitante</li>}
                </ul>
              </div>}
              <div className="resumo__informacoes-legais">
                <h2 className="titulo titulo_medio texto_cor-secundaria">Informações</h2>
                <p>
                  O(s) <strong>pretendentes(s)</strong> cadastrado no sistema eletrônico desenvolvido pela Agência de Habilitação Popular do Estado de Mato Grosso do Sul - <strong>AGEHAB</strong> fica ciente que:
                </p>

                <ol className="comprovante-informacoes__lista-ordenada">
                  <li>A Inscrição é <strong>totalmente gratuita</strong>;</li>
                  <li>A ordem cronológica de inscrição (tempo de inscrição) não é critério de seleção;</li>
                  <li>O comprovante de inscrição compartilhada apenas confirma o cadastramento, não gerando direitos;</li>
                  <li>O imóvel pretendido deverá ser destinado para a sua <strong>moradia</strong> e de seus familiares;</li>
                  <li>Não poderá ser proprietário de imóvel residencial;</li>
                  <li>Deverá manter seus dados atualizados para participar dos eventuais processos de seleção;</li>
                  <li>As informações prestadas deverão ser comprovadas no processo de seleção;</li>
                  <li>Os programas habitacionais ofertados poderão ter outras exigências complementares;</li>
                  <li>Ficará responsável por todas as informações prestadas, e que dados falsos o eliminará automaticamente dos programas habitacionais do Governo do Estado de MS.</li>
                </ol>
              </div>
            </ContainerConteudo>
            <ContainerRodape>
              <button
                className="botao botao_medio botao_cor-secundaria botao_largura-total"
                onClick={() => {
                  abrirTelaDeComprovante();
                }}>
                Gerar comprovante de inscrição
              </button>
            </ContainerRodape>
          </Container>
        </div>
      </div>
      }
    </>
  );

};
export default ResumoDaInscricao;