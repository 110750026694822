import { useEffect, useState } from 'react';
import { Cartao, BotaoComTexto, EstadoVazio } from '@digix-ui/componentes';
import {
  selecionarLocalDeAtendimento,
  permitirAlterarLocalDeAtendimento,
  obterLocaisDeAtendimentoDoUsuario,
} from '../../actions/informacoesDeLocalDeAtendimento.actions';
import Container from '../../componentes/Container';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import BarraDeNavegacao from '../../componentes/BarraDeNavegacao';

export default function LocaisDeAtendimento() {
  const [locaisDeAtendimento, carregarLocaisDeAtendimento] = useState([]);
  const [possuiLocaisDeAtendimento, setarSePossuiLocaisDeAtendimento] = useState(false);
  const obterLoginDoUsuario = () => localStorage.getItem('loginDoUsuario');

  const dispatch = useDispatch();
  useEffect(() => {
    if (obterLoginDoUsuario())
      obterLocaisDeAtendimentoDoUsuario(obterLoginDoUsuario())
        .then(data => {
          const locaisDeAtendimento = data;
          const possuiApenasUmLocalDeAtendimento = !!locaisDeAtendimento && locaisDeAtendimento.length === 1;
          const possuiLocais = !!locaisDeAtendimento && locaisDeAtendimento.length > 0;
          setarSePossuiLocaisDeAtendimento(possuiLocais);
          const podeAlterarLocalDeAtendimento = !possuiApenasUmLocalDeAtendimento;
          permitirMudarLocalDeAtendimento(podeAlterarLocalDeAtendimento);
          if (possuiApenasUmLocalDeAtendimento)
            selecionarLocalEscolhido(locaisDeAtendimento[0]);
          else {
            carregarLocaisDeAtendimento(locaisDeAtendimento);
          }
        })
        .catch(error => console.log(error));
  }, [obterLoginDoUsuario()]);


  const selecionarLocalEscolhido = localDeAtendimento => {
    dispatch(selecionarLocalDeAtendimento(localDeAtendimento));
    localStorage.setItem('localDeAtendimento', JSON.stringify(localDeAtendimento));
    localStorage.removeItem('pesquisaDeProtocolos');
    dispatch(push('listagem-de-inscricoes'));
  };

  const permitirMudarLocalDeAtendimento = podeAlterarLocalDeAtendimento => {
    dispatch(permitirAlterarLocalDeAtendimento(podeAlterarLocalDeAtendimento));
    localStorage.setItem('podeAlterarLocalDeAtendimento', podeAlterarLocalDeAtendimento);
  };

  return (
    <>
      <BarraDeNavegacao titulo="Locais de Atendimento" />
      <Container comFundo={true} semPadding={false}>
        {!possuiLocaisDeAtendimento && (
          <EstadoVazio
            icone='far fa-exclamation-circle'
            mensagem='Você não possui acesso a um local de atendimento'
            descricao='Entre em contato com responsável do setor de inscrições'
            cor='erro'
            altura='pequeno'
            largura='pequeno'
          />
        )}

        {possuiLocaisDeAtendimento && (
          <>
            <div className='container__cabecalho_texto-centralizado texto_cor-terciaria u-margem-inferior-media'>
              <div className='grade__linha_alinhar-horizontal-centro u-margem-inferior-media'>
                <i className='far fa-map-marker-alt fa-2x'></i>
              </div>
              <h2 className='titulo_medio'>Selecione um local de atendimento</h2>
            </div>
            <ul data-test-id='locais-de-atendimento'>
              {locaisDeAtendimento.map(localDeAtendimento => (
                <li
                  className='u-lista-sem-marcadores u-margem-superior-media'
                  key={localDeAtendimento.id}
                >
                  <Cartao
                    icone='far fa-building fa_cor-terciaria'
                    titulo={localDeAtendimento.descricao}
                    subtitulo={localDeAtendimento.cidadeComUf}
                    textoDoBotao='Selecionar'
                    iconeDoBotao='far fa-arrow-right'
                    visualDoBotao='contorno'
                    cor='terciaria'
                    containerSecundario={
                      <BotaoComTexto
                        key={'botao-localDeAtendimento-' + localDeAtendimento.id}
                        tipo='button'
                        cor='terciaria'
                        tamanho='pequeno'
                        onClick={() => selecionarLocalEscolhido(localDeAtendimento)}
                      >
                        Selecionar
                      </BotaoComTexto>
                    }
                  ></Cartao>
                </li>
              ))}
            </ul>
          </>
        )}
      </Container>
    </>
  );
}