import React, { useEffect, useState } from 'react';
import CampoDeSelecaoAssincrono from '../../componentes/CampoDeSelecaoAssincrono';
import MensagemDeErro from '../../componentes/MensagemDeErro';
import { BotaoComTextoEIcone, Mensagem } from '@digix-ui/componentes';
import IDeficiencia from '../../interfaces/IDeficiencia';
import ICid from '../../interfaces/ICid';
import CampoCheckbox from '../../componentes/CampoCheckbox';
//@ts-ignore
import Select from 'react-select';
import ITipoDeDeficiencia from '../../interfaces/ITipoDeDeficiencia';
import { mapearTiposDeDeficienciaParaSelect } from '../../selectors';

interface ICampoDeSelecaoAssincronoProps {
  value: number,
  label: string,
}

interface IEventosDeficienciaProps {
  buscarCid: (cid: string) => void,
  adicionarDeficiencia: (deficiencia: IDeficiencia) => void,
  removerDeficiencia: (cid: ICid) => void,
}

interface ICampoDeficienciaProps {
  possuiDeficiencia: boolean,
  deficiencias?: IDeficiencia[],
  erroDeficiencias?: boolean,
  eventosDeficiencia: IEventosDeficienciaProps,
  onChangePossuiDeficiencia: (event: React.ChangeEvent<HTMLInputElement>) => void,
  afirmacaoPossuiDeficiencia: string,
  perguntaPossuiDeficiencia: string,
  disabled: boolean,
  tiposDeDeficiencia: ITipoDeDeficiencia[]
}

const CampoDeficiencia = ({ possuiDeficiencia, deficiencias = [], erroDeficiencias, eventosDeficiencia, onChangePossuiDeficiencia,
  afirmacaoPossuiDeficiencia, perguntaPossuiDeficiencia, disabled, tiposDeDeficiencia }: ICampoDeficienciaProps) => {

  const [tipoDeDeficiencia, setTipoDeDeficiencia] = useState<ITipoDeDeficiencia | null>();
  const [cid, setCid] = useState<ICid | null>();
  const [cidSelecionado, setCidSelecionado] = useState<ICampoDeSelecaoAssincronoProps | null>();
  const deficienciasComCid = deficiencias.filter(d => d.cid);
  const tipoDeDeficienciaDeDeficienciaSemCid = deficiencias.find(d => !d.cid)?.tipoDeDeficiencia;
  const erroTipoDeDeficiencia = erroDeficiencias && !tipoDeDeficiencia?.id;
  const erroCid = erroDeficiencias && !cidSelecionado;

  useEffect(() => {
    if (tipoDeDeficienciaDeDeficienciaSemCid)
      setTipoDeDeficiencia(tipoDeDeficienciaDeDeficienciaSemCid);
  }, [tipoDeDeficienciaDeDeficienciaSemCid]);

  const selecionaTipoDeDeficiencia = (tipoDeDeficienciaSelecionado: { value: number, label: string }) => {
    setTipoDeDeficiencia(tipoDeDeficienciaSelecionado ? { id: tipoDeDeficienciaSelecionado.value, nome: tipoDeDeficienciaSelecionado.label } as ITipoDeDeficiencia : {} as ITipoDeDeficiencia);
  };

  const selecionaCid = (cid: ICampoDeSelecaoAssincronoProps) => {
    setCidSelecionado(cid);
    setCid(cid ? {
      id: cid.value,
      codigo: cid.label.split(' - ')[0],
      descricao: cid.label.split(' - ')[1]
    } as ICid : null);
  };

  const adicionarDeficiencia = () => {
    const deficiencia = {
      cid: cid,
      tipoDeDeficiencia: tipoDeDeficiencia
    };
    eventosDeficiencia.adicionarDeficiencia(deficiencia as IDeficiencia);
    setTipoDeDeficiencia({} as ITipoDeDeficiencia);
    setCidSelecionado(null);
  };

  return (
    <div className="grade__coluna">
      <h2 className="titulo titulo_pequeno texto_cor-secundaria">
        <strong>Deficiência</strong>
      </h2>
      <div className='grade__linha'>
        <div className='grade__coluna'>
          <div className="formulario__label">
            {perguntaPossuiDeficiencia}
          </div>
          <CampoCheckbox
            name='possuiDeficiencia'
            label={afirmacaoPossuiDeficiencia}
            checked={possuiDeficiencia}
            //@ts-ignore
            onChange={onChangePossuiDeficiencia}
            disabled={disabled}
          />

          {possuiDeficiencia &&
            <>
              <Mensagem
                tipo='atencao'
                icone='far fa-exclamation-circle'>
                Caso esta informação <strong> não seja comprovada</strong> no futuro, a inscrição pode ser <strong> desclassificada de processos de seleção</strong>
              </Mensagem>

              <div className="u-margem-superior-media">
                <label className="formulario__label">
                  Informe a CID (Classificação Internacional de Doenças) para cada deficiência
                </label>
                <p className="formulario__descricao">A CID pode ser encontrado no atestado médico emitido pelo profissional responsável, que comprovará a deficiência de acordo com as definições do Decreto nº 3.298/99 (artigos 3º e 4º) e com as alterações dadas pelo Decreto nº 5.296/2004.</p>
                <section className="cartao">
                  <div className="grade__linha grade__linha_alinhar-vertical-a-base">
                    <div className="grade__coluna grade__coluna_4">
                      <div className={`formulario__input-react-select ${erroTipoDeDeficiencia && 'formulario__input-react-select_erro'}`}>
                        <label className="formulario__label">
                          Tipo de deficiência
                        </label>
                        {erroTipoDeDeficiencia && <MensagemDeErro campo='tipoDeDeficiencia' />}
                        <Select
                          name="tipoDeDeficiencia"
                          id="tipoDeDeficiencia"
                          placeholder="Selecione uma opção"
                          value={tipoDeDeficiencia?.id}
                          options={mapearTiposDeDeficienciaParaSelect(tiposDeDeficiencia)}
                          onChange={selecionaTipoDeDeficiencia}
                          disabled={disabled} />
                      </div>
                    </div>
                    <div className="grade__coluna grade__coluna_6">
                      <div className={`formulario__input-react-select ${erroCid && 'formulario__input-react-select_erro'}`}>
                        <label className="formulario__label">
                          CID
                        </label>
                        {erroCid && <MensagemDeErro campo="deficiencia" />}
                        <CampoDeSelecaoAssincrono
                          id="deficiencia"
                          name="deficiencia"
                          placeholder="Selecione a CID"
                          textoDeBusca="Selecione a CID"
                          onChange={selecionaCid}
                          obterOpcoes={eventosDeficiencia.buscarCid}
                          disabled={disabled}
                          value={cidSelecionado}
                        />
                      </div>
                    </div>
                    <div className="grade__coluna grade__coluna_2">
                      <button
                        className="botao botao_medio botao_cor-secundaria" disabled={!(tipoDeDeficiencia != null && cidSelecionado != null)} onClick={adicionarDeficiencia}>
                        Adicionar
                      </button>
                    </div>
                  </div>
                </section>
              </div>
              {deficienciasComCid.length > 0 &&
                <div className="u-margem-superior-media">
                  <label className="formulario__label">
                    Lista de Deficiências
                  </label>
                  {deficienciasComCid.map((deficiencia: IDeficiencia) => (
                    <section className="cartao cartao_-cor-secundaria cartao-alinhamento-inferior" key={deficiencia.cid.id}>
                      <div className="cartao__cabecalho">
                        <div className="cartao__container-primario">
                          <h2 className="cartao__titulo">{deficiencia.cid.codigo}</h2>
                          <p className="cartao__subtitulo">{deficiencia.cid.descricao}</p>
                          <p className="lista-de-cadastro__tag">{deficiencia.tipoDeDeficiencia.nome}</p>
                        </div>
                        <div className="cartao__container-secundario">
                          <div className='alinhamento-superior'>
                            <BotaoComTextoEIcone
                              tipo="button"
                              cor="perigo"
                              visual="contorno"
                              tamanho="pequeno"
                              icone="far fa-trash"
                              posicaoDoIcone="direita"
                              habilitado={!disabled}
                              onClick={() => eventosDeficiencia.removerDeficiencia(deficiencia.cid)}>
                              Remover
                            </BotaoComTextoEIcone>
                          </div>
                        </div>
                      </div>
                    </section>
                  ))}
                </div>
              }
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default CampoDeficiencia;